@import "@/styles/scss/_base.scss";
@import "@/styles/scss/_util.scss";

@-webkit-keyframes bg-color {
  0% { background-color: $hpad-white; }
  50% { background-color: $hpad-orange; }
  100% { background-color: $hpad-white; }
}

@keyframes bg-color {
  0% { background-color: $hpad-white; }
  50% { background-color: $hpad-orange; }
  100% { background-color: $hpad-white; }
}

#setting-pairing-tab {
  #pairing-table {
    @extend %hpad-table-1;

    table {
      width: 100%;

      .outingBg {
        background-color: #fff8f8;
        z-index: -20;
      }

      tr {
        td {
          position: relative;
          z-index: 10;
        }

        .outingText {
          display: inline-block;
          position: absolute;
          width: 0px;
          top: 19%;
          left: 0;

          white-space: nowrap;
          color: #FFE0E0;
          font-size: 40pt;
          font-style: oblique;
          z-index: -10;
        }
      }
    }

    .pairing-btn {
      background-color: $hpad-gray;
      border-radius: 10px;
      padding: 10px;
      text-align: center;

      .icon {
        display: inline-block;
        font-size: 26px;
        color: $hpad-white;
      }
    }

    .active {
      background-color: $hpad-orange;
    }

    .click {
      animation: bg-color 0.6s;
      -webkit-animation: bg-color 0.6s;
    }
  }

  .error {
    padding-left: 70px;
    color: $hpad-red;
  }
}