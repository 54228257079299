@import "@/styles/scss/_base.scss";
@import "@/styles/scss/_util.scss";

/*
 * タイムラインスタイル
 */
.timeline {
  overflow: hidden;

  .vis-timeline {
    visibility: visible !important;
    min-width: 1500px;
  }

  .vis-range {
    border-radius: 5px;
    color: #FFFFFF;
    border: none;
    line-height: 100%;
    height:100%;
    font-size: 17px;
  }

  .box-type-class {
    color: #000;
  }

  .item-urine {
    background-color: #5876FF;
  }

  .item-watery {
    background-color:#92D050;
  }

  .item-feces {
    background-color: #FFB500;
  }

  .item-none {
    background-color: #BFBFBF;
    border: solid 2px #838383;
  }

  .item-blank {
    background-color: transparent;
  }

  .item-leaked {
    div{
      color: #FE5050;
    }
  }

  .item-laxative {
    background-image: url("../../../../images/laxative.svg");
    background-size: contain;
    background-color: rgba(255, 255, 255, 0.1);
    background-repeat: no-repeat;
    z-index: 10;
    opacity: 0.5;
  }

  .vis-item-overflow {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .vis-item-content {
    text-align: center;
  }

  .error {
    padding-left: 70px;
    color: $hpad-red;
  }

  .vis-inner {
    display: flex !important;
    justify-content: flex-start;

    .careperson-group {
      width: 42%;

      color: #215ac9;
      text-decoration: underline;

      cursor: pointer;

      .place, .name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .vis-left {
    width: 20%;
  }
  #timeline-item-tooltip-table {
    max-width: 800px;
    th {
      text-align: right;
      padding: 5px 5px 0 0;
    }
    .first-row {
      padding-top: 0 !important;
    }
    td {
      max-width: inherit;
    }
  }
  .careperson-group:hover {
    color: #4f82e6;
  }
}
