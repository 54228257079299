@import "@/styles/scss/_base.scss";
@import "@/styles/scss/_util.scss";

/*
 * 排泄結果個別スタイル
 */
#individual-excretion-results {
  box-sizing: border-box;
  width: 100%;
  padding: 1rem 1rem 0 1rem;

  .head-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 1rem 0;

    .head-content-left-block {
      display: flex;
      align-items: center;
    }
  }

  .backButton {
    width: 47px;
    padding: 0;

    .MuiIconButton-label {
      flex-direction: column;
      justify-content: initial;

      .MuiSvgIcon-root {
        font-size: 2rem;
      }
    }

    .backButtonLabel {
      font-size: 11px;
    }
  }

  // ユーザ選択
  .users {
    font-size: 1rem;
    margin: 0 0 0 1rem;
    padding: 0 0 5px 5px;

    select {
      max-width: 300px;
    }
  }

  .left-fields {
    width: 270px;
    margin: 0 1rem 0 0;
  }

  .average-excretion-amount-select {
    margin: 1rem 0 0 0;

    .average-excretion-amount-select-label {
      font-size: 14px;
      color: #565656;
    }

    .average {
      width: 100%;
      padding: 0;
      margin: 5px 0 0 0;

      select {
        width: 100%;
        line-height: 1rem;
      }
    }
  }

  .sort-select {
    margin: 1rem 0 0 0;

    .sort-select-label {
      font-size: 14px;
      color: #565656;
    }

    .hpad-pulldown-1 {
      width: 100%;
      padding: 0;
      margin: 5px 0 0 0;

      select {
        width: 100%;
        line-height: 1rem;
      }
    }
  }

  .note-space {
    margin: auto 0;
    .notes {
      display: inline-block;
      vertical-align: middle;
      margin: auto 1rem;
      span {
        margin: 0 0.5rem;
      }
      span::before {
        content: '■';
      }
      .urine::before {
        color: #5876ff;
      }
      .watery::before {
        color: #92d050;
      }
      .feces::before {
        color: #ffb500;
      }
      .none::before {
        color: #bfbfbf;
      }
      .laxative::before {
        content: '';
        display: inline-block;
        width: 1rem;
        height: 1rem;
        background-image: url(../images/laxative.svg);
        background-size: contain;
        vertical-align: middle;
      }
    }
  }
}
