// 補助線の色
$addLineColor :#e5e5e5;

// 個別排泄記録
.indivisualTimeline{
  // 量平均の3時間を選択時の補助線
  &.three-time {
    .vis-time-axis {
      .vis-h3, .vis-h6, .vis-h9, .vis-h12, .vis-h15, .vis-h18, .vis-h21 {
        border-color:$addLineColor;
        border-width: 5px;
      }
    }
  }

  // 量平均の6時間を選択時の補助線
  &.six-time {
    .vis-h6, .vis-h12, .vis-h18 {
      border-color: $addLineColor;
      border-width: 5px;
    }
  }

  .average-header{
    height:40px;
    white-space: nowrap;
    width: 140px;
    vertical-align: top;
  }

  .item-blank-average{
    background-color: transparent;
    padding: 0px 1px;
    border-radius: 0px;
    & > .vis-item-overflow {
      background-color: white;
    }
  }

  .group-item {
    display: flex;
    height:100%;
  }

  .vis-labelset .vis-label .vis-inner {
    padding:0px;
  }

  .vis-left {
    width: 300px;
  }
}