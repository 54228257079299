@import "@/styles/scss/_base.scss";
@import "@/styles/scss/_util.scss";
@import "@/styles/breakpoints.scss";

#setting-group-tab {
  // フォームのスタイル
  .rjsf {
    display: flex;
    align-items: center;

    .field {
      display: flex;
      align-items: center;

      @media screen and (max-width: $sm) {
        align-items: flex-start;
        flex-direction: column;
      }

      .control-label {
        width: 9rem;
        margin: 0 1rem 0 0;
      }
    }

    & input {
      padding: 5px 10px;
      border-radius: 5px;
    }
  }

  // グループメンバー管理設定
  .manage-group-member-section {
    margin: 2rem 0 2rem 0;

    .manage-group-member-form-container {
      .input-target-resident-form {
        display: flex;
        align-items: center;

        .input-target-resident-form-label {
          width: 9rem;
          margin: 0 1rem 0 0;
        }

        & .hide {
          display: none;
        }
      }

      .select-group-form-container {
        margin: 0 0 0 1rem;
      }
    }

    .group-member-box-list {
      margin: 2rem 0 0 0;

      .group-box {
        position: relative;
        border: 1px solid #ddd;
        border-width: 1px 0;
        padding: 20px 0 2rem;
        width: 48%;
      }
    }

    .delete-selected-groups-button {
      margin: 1rem 0 0 0;
    }
  }

  button[type="submit"] {
    margin-left: 1rem;
  }

  .error-message {
    margin: 0.5rem 0 0 0;
    font-size: 14px;
    color: #cc0000;
  }

  .delete-selected-groups-button {
    cursor: pointer;
    color: #cc0000;
    font-size: 14px;
  }
}
