
.notification-table-header {
  th {
    text-align: left;

    font-size: 1rem;
    line-height: 1.7rem;
    font-weight: normal;
    color: #808080;
    border-bottom: 1px solid #E0E0E0;
  }

  .room-resident {
    padding-left: 140px;
  }

  .notified-at {
    text-align: center;
  }
}