@import "@/styles/breakpoints.scss";

.sensor-table {
  th {
    padding: 1rem 0 1rem 0;
    font-weight: normal;
    color: #fff;
    border: 1px solid #ddd;
    background-color: #90a4ae;
  }

  td {
    padding: 1rem 0 1rem 1rem;
    border: 1px solid #ddd;
    vertical-align: middle;

    &.action {
      .delete-btn {
        display: flex;
        align-items: center;
        float: left;
        color: #cc0000;

        &:hover {
          cursor: pointer;
        }

        .icon {
          font-size: 20px;
          margin: 0 2px 0 0;
        }

        .label {
          font-size: 1rem;
        }
      }
    }
  }

  table {
    width: 340px;

    @media screen and (min-width: $sm) {
      width: 740px;
    }
  }

  tr:hover {
    transition: all 0.25s cubic-bezier(0.22, 0.61, 0.36, 1);
    background-color: #e0f2f1;
  }

  .sensor_id {
    width: 85%;
  }

  .action {
    width: 15%;
  }

  .sensor-table-content {
    @media screen and (min-width: $sm) {
      width: 760px;
    }
  }
}
