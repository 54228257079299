
.group-header {
  position: relative;
  width: 142px;
  height: 40px;

  background-image: linear-gradient(
    to top right,
    transparent, transparent 49%,
    #565656 50%, #565656 50%,
    transparent 51%, transparent
  );

  font-size: 15px;
  border-left: 1px solid #565656;

  .average-header-label {
    display: inline-block;
    position: absolute;
    top: 10%;
    right: -5%;
  }

  .total-header-label {
    display: inline-block;
    position: absolute;
    bottom: 10%;
    left: 10%;
  }
}

.date {
  box-sizing: border-box;
  cursor: pointer;
  width: 140px;
  padding: 0 0 0 1rem;
  white-space: nowrap;

  font-size: 1rem;
  line-height: 40px;
}

.current-date {
  background-color:#F2992B;
  color: white;
  width: 140px;
}

.daily-excretion-counts {
  display: flex;
  justify-content: flex-start;

  height: 40px;
  font-size: 18px;
  width: 160px;
  border-left: 1px #565656 solid;

  font-size: 1rem;

  .daily-excretion-count {
    display: inline-flex;
    align-items: center;
    margin: 2px;
    padding: 5px;

    border-radius: 2px;
    color: white;
  }

  .item-none {
    border: none;
  }

  .leak-count {
    display: inline-block;
    width: 60px;
    font-size: 1rem;
    margin: 0 0 0 5px;

    .leakage-count-label {
      display: block;
      font-size: 10px;
      margin: 3px 0 5px 0;
    }
  }

  .item-leaked {
    color: #FE5050;
  }
}