@import "@/styles/breakpoints.scss";

#setting-laxative-tab {
  padding: 50px;
  border: 1px #ddd solid;

  .raise-priority-duration-box {
    .priority-threshold-config {
      margin: 1rem 0 0 1rem;

      .priority-threshold-form {
        display: flex;
        align-items: center;

        font-size: 1rem;

        .raise-priority-duration-input {
          .control-label {
            display: none;
          }

          #root_alert_prioritize_hour {
            padding: 3px 3px 3px 8px;
            border: 1px #ddd solid;
            border-radius: 4px;
          }
        }

        .hour-unit {
          margin: 0 0 0 0.5rem;
          font-size: 1rem;
          font-weight: lighter;
          color: #565656;
        }

      }
    }
  }

  .add-laxative-box {
    margin: 2rem 0 0 0;

    .add-laxative-form {
      display: flex;
      align-items: center;
      margin: 1rem 0 0 1rem;

      .laxative-name-input {
        position: relative;
        display: flex;
        align-items: center;

        .control-label {
          box-sizing: border-box;
          min-width: 10rem;
          padding: 0 1rem 0 0;
          font-size: 1rem;
          color: #565656;
        }

        input {
          padding: 3px 3px 3px 8px;
          border-radius: 4px;
        }

        .help-block {
          position: absolute;
          top: 7px;
          left: 27rem;

          font-size: 14px;
          white-space: nowrap;
          color: #cc0000;
        }
      }
    }

    .laxative-list-box {
      margin: 1rem 0 0 1rem;

      .laxative-counter {
        display: flex;
        margin: 0 0 0.5rem 0;
        font-size: 16px;
        color: #565656;

        .counter {
          margin: 0 0 0 0.5rem;
        }
      }

      #sortable-list-laxative-list {
        width: 20rem;

        list-style: none;
        border: 1px #efefef solid;
        border-radius: 4px;
        font-size: 1rem;
        color: #565656;

        li {
          border-bottom: 1px #efefef solid;

          .sortable-list-elem-value {
            display: inline-block;
            box-sizing: border-box;
            min-width: 17.5rem;

            padding: 12px 0 12px 1rem;
          }

          .sortable-list-elem-delete-button {
            margin: initial;
            padding: initial;
            border-radius: initial;

            font-size: 1rem;
            background-color: #fff;
            color: #565656;
          }
        }
      }
    }
  }


  .laxative-list {
    border-collapse: collapse;

    tr {
      .laxative-name {
        border: 1px solid #999;

        .user-addable-laxative {
          padding: 0px;
        }
      }

      td {
        font-size: 18px;
        padding: 3px;

        .laxative-delete-button {
          background-color: #ff7777;
          border-radius: 15px;
          font-size: 28px;
          font-weight: bold;
          color: #fff;
          cursor: pointer;
        }
      }

      .laxative-delete-button-td {
        padding-top: 6px;
      }
    }
  }

  button[type="submit"] {
    margin: 0 0 0 1rem;
  }
}
