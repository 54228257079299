@import "@/styles/breakpoints.scss";

// $sm だとタブがすべて入りきらないので
$lr-sm: $sm + 200px;

.setting-tab {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.5rem 1rem;

  border: solid #ddd;
  border-width: 1px 1px 0px;
  border-radius: 5px 5px 0 0;
  color: #90a4ae;
  background-color: #fff;

  @media screen and (min-width: $lr-sm) {
    min-width: 5rem;
  }

  @media screen and (min-width: $md) {
    min-width: 7rem;
    margin: 0 5px 0 0;
    padding: 10px 1rem 0.5rem 12px;
  }

  &.active {
    background-color: #90a4ae;
    color: #fff;
  }

  &:hover {
    transition: all 0.25s cubic-bezier(0.22, 0.61, 0.36, 1);
    background-color: #90a4ae;
    color: #fff;
  }

  // アイコン
  .icon {
    font-size: 25px;

    @media screen and (min-width: $lr-sm) {
      font-size: 2rem;
      margin-right: 2px;
    }

    &.icon-icons_laxative {
      display: grid;
      place-items: center;
      box-sizing: border-box;

      height: 2rem;
      padding: 0 5px 0 0;

      font-size: 22px !important;
    }
  }

  .tab-label {
    display: none;
    font-family: auto;
    font-size: 14px;

    @media screen and (min-width: $lr-sm) {
      display: block;
    }

    @media screen and (min-width: $md) {
      font-size: 1rem;
    }
  }
}