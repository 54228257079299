.icon-icons_signal {
  display: grid;
  place-items: center;
  box-sizing: border-box;

  height: 2rem;
  padding: 0 2px 0 0;

  font-size: 28px !important;

  &::before {
    content: "\E00D"
  }
}