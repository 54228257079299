#settings {
  margin: 2rem auto;

  .setting-tab-content {
    box-sizing: border-box;
    min-height: 720px;
    padding: 50px 50px 50px 50px;
    border: 1px #ddd solid;
  }

  .setting-heading {
    margin: 0 0 1rem 0 !important;
    font-size: 28px !important;
    color: #565656;
  }

  .setting-sub-heading {
    margin: 0 0 14px 0 !important;
    font-size: 22px !important;
    color: #565656;
  }

  .setting-field-label {
    display: inline-flex;
    min-width: 10rem;
    padding: 0 1rem 0 0;

    font-size: 1rem;
    color: #565656;
    align-items: center;
  }

  .setting-description {
    display: block;
    margin: 0 0 1.5rem 0 !important;
    font-family: auto;
    font-size: 1rem;
    color: #565656;
  }

  .form-group {
    input.form-control {
      padding: 3px 3px 3px 8px;
      border-radius: 4px;
    }
  }

  button {
    padding: 5px 1rem;
    font-size: 14px;
    border-radius: 5px;
  }

  button[type="submit"] {
    color: #fff;
    background-color: #f39a2b;
  }
}

