@import "@/styles/scss/_base.scss";
@import "@/styles/scss/_util.scss";

/*
 * 排泄結果個別スタイル
 */
#excretion-results {
  width: 100%;

  .ml10 {
    margin-left: 10px;
  }
  .mt10 {
    margin-top: 10px;
  }
  *:focus {
    outline: none;
  }

  .pulldown {
    color: $hpad-black;
    vertical-align: middle;
    position: relative;

    select {
      display: inline-block;
      appearance: none;
      min-width: 200px;
      padding: 8px;
      border-radius: 0px;
      background-color: $hpad-white;
      border: 1px solid $hpad-border-gray;
      color: $hpad-black;
    }

    &::after {
      content: "▼";
      position: absolute;
      top: 12px;
      right: 10px;
      font-size: 10px;
      color: $hpad-gray;
      pointer-events: none;
    }
  }

  .app_container {
    width: 770px;
  }

  .title-frame {
    box-sizing: border-box;
    width: 100%;
    padding: 1rem 1rem 0 1rem;

    .select-fields {
      white-space: nowrap;
    }

    .title {
      display: inline-block;
      padding: 0 0 0 5px;
    }

    .setting-space {
      margin: auto 0;
      vertical-align: middle;

      button {
        cursor: pointer;
      }

      .groups {
        display: inline-block;
        padding: 0 0 5px 5px;
        margin: auto 0;
      }
    }

    .timeline-container {
      display: flex;
      width: 100%;
      margin: 1rem auto 0;
    }

    // TODO: CareNoteDatePicker に置き換えるときに削除する
    ._calendar-date-picker {
      display: inline-block;
      text-align: center;

      margin: 0 1rem 0 0;

      .today-select-button {
        margin: 0 0 0.5rem 0;
        padding: 4px 8px;

        border-radius: 5px;
        background-color: $hpad-orange;
        color: $hpad-white;
        font-size: 14px;

        & > div {
          vertical-align: middle;
          display: inline-block;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .rjsf {
        .form-group fieldset .form-group .datetime-picker {
          .without-time {
            width: initial;
            z-index: 10;
            padding: 0.5rem;
          }
        }

        th {
          padding: 3px;
        }

        .datetime-picker {

          .toolbar {
            display: flex;
            justify-content: space-between;

            width: initial;
            margin: 0 0 0.5rem 0;

            .current-month {
              width: initial;
              font-weight: bold;
            }
          }

        }

        button[type="submit"] {
          display: none;
        }
      }
    }

    .explanation {
      display: inline-block;
      vertical-align: middle;
      font-size: 0.8rem;
      margin: auto 1rem;
    }

    .note-space {
      margin: auto 0;
      .notes {
        display: inline-block;
        vertical-align: middle;
        margin: auto 1rem;
        span {
          margin: 0 0.5rem;
        }
        span::before {
          content: "■";
        }
        .urine::before {
          color: #5876ff;
        }
        .watery::before {
          color: #92d050;
        }
        .feces::before {
          color: #ffb500;
        }
        .none::before {
          color: #bfbfbf;
        }
        .laxative::before {
          content: '';
          display: inline-block;
          width: 1rem;
          height: 1rem;
          background-image: url(../images/laxative.svg);
          background-size: contain;
          vertical-align: middle;
        }
      }
    }
  }

  .title {
    font-size: 21px;
    // color: $hpad-gray;
    font-weight: bold;
  }

  .resident-not-found-dialog {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 400px;
    height: 100px;
    margin: auto;
    text-align: center;

    .title {
      font-size: 1.5rem;
      font-weight: normal;
      color: $hpad-gray;
    }

    .center-add-btn {
      display: inline-block;
      margin-top: 20px;
      padding: 10px 20px;

      border-radius: 5px;
      background-color: $hpad-orange;
      color: $hpad-white;
      font-size: 1rem;
    }
  }
}
