@import '@/styles/scss/_base.scss';
@import '@/styles/scss/_util.scss';

/*
 * 利用者詳細ページ
 */
#userDetail {
  white-space: nowrap;

  .title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 8px;
  }

  .main-content {
    width: 100%;
    margin: 0px 100px;

    #user-data {
      width: 29%;
      min-width: 500px;

      .userDataBtn {
        font-size: 30px;
        margin-left: 10px;
        p {
          font-size: 13px;
        }
      }

      .editBtn {
        color: $hpad-gray;
      }

      .removeBtn {
        cursor: pointer;
        color: $hpad-red;
      }

      #user-data-table {
        @extend %hpad-table-1;
        table {
          width: 100%;
        }

        #name {
          font-size: 24px;
          span {
            padding-left: 10px;
            font-size: 14px;
          }
        }

        .user-status {
          font-size: 15px;
          .icon {
            font-size: 25px;
            color: $hpad-gray;
          }
        }
      }

      .histogram-duration-selector {
        margin: 5px 0;
      }
    }

    .input-field {
      button[type='button'] {
        margin: 3px;
        padding: 5px 10px;
        border-radius: 10px;
        background-color: $hpad-orange;
        color: $hpad-white;
        cursor: pointer;
        &:disabled {
          background-color: $hpad-gray;
          cursor: default;
        }
      }

      .hpad-pulldown-1 {
        .histogram-mode {
          width: 240px;
        }
      }

      .calcForm {
        margin: 5px 0;

        .clickableElem {
          height: 25px;
          width: 210px;
          cursor: pointer;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          -webkit-transition: all 0.3s;
          transition: all 0.3s;

          .calcTextBg {
            position: absolute;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: $hpad-orange;
            padding: 5px;
          }

          .calcText {
            position: absolute;
            color: white;
            margin-top: 6px;
            margin-left: 6px;
          }
        }

        .clickableElem:hover {
          background-color: $hpad-orange;
          color: white;
          border-radius: 30px;
        }

        .hide {
          display: none;
        }

        .hide-animation {
          height: 0px !important;
          opacity: 0 !important;
          overflow: hidden;
        }

        .calcTable {
          display: block;
          width: 437px;
          height: 168px;
          margin-left: 24px;
          opacity: 1;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          -webkit-transition: all 0.3s;
          transition: all 0.3s;
          .calcRow {
            display: table-row;
            .calcCell {
              display: table-cell;
              vertical-align: middle;
            }
          }

          .pl35 {
            padding-left: 35px;
          }
        }

        table {
          width: 437px;
        }

        button[type='button'] {
          margin: 4px 0 0 10%;
          padding: 3px 10px;
          border-radius: 8px;
          background-color: $hpad-orange;
          color: $hpad-white;
          cursor: pointer;
          &:disabled {
            background-color: $hpad-gray;
            cursor: default;
          }
        }
        .text-right {
          text-align: right;
        }
      }
      .pulldown {
        color: $hpad-black;
        vertical-align: middle;
        position: relative;
        & > p {
          display: inline-block;
          margin-right: 10px;
        }
        span {
          padding-right: 10px;
        }
        select {
          display: inline-block;
          appearance: none;
          min-width: 50px;
          padding: 8px;
          margin: 3px;
          border-radius: 0px;
          background-color: $hpad-white;
          border: 1px solid $hpad-border-gray;
          color: $hpad-black;
        }
        &::after {
          content: '▼';
          position: absolute;
          top: 18px;
          left: 33px;
          font-size: 10px;
          color: $hpad-gray;
          pointer-events: none;
        }
      }
    }

    #user-Alert-table {
      width: 37%;
      min-width: 626px;

      @extend %hpad-table-1;
      table {
        width: 454px;
      }
      .drag-content {
        width: 270px;
      }
      .table-block {
        display: block;
      }
      .kindWidth {
        width: 130px;
      }
      .alertDateWidth {
        width: 323px;
      }
      #user-alert-log-tbody {
        height: 219px;
        overflow-y: scroll;
      }

      // 図形
      .left-triangle {
        display: inline-block;
        position: absolute;
        margin-top: -10px;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 14px solid #6aa7cd;
      }
      .right-triangle {
        display: inline-block;
        position: absolute;
        margin-top: -10px;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 14px solid #6aa7cd;
      }
      .circle {
        display: inline-block;
        position: absolute;
        margin-top: 10px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #00c875;
      }
    }

    .karte-area {
      width: 33%;
      min-width: 600px;

      .control-label {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
        display: block;
      }
      textarea {
        border: 1px solid $hpad-border-gray;
        box-sizing: border-box;
        width: 100%;
        min-height: 246px;
        padding: 20px;
        font-size: 15px;
        resize: none;
      }
      button[type='submit'] {
        padding: 10px 20px;
        background-color: $hpad-orange;
        color: $hpad-white;
        border-radius: 10px;
        cursor: pointer;
      }
      .error {
        color: $hpad-red;
      }
    }

    .duration-error {
      color: $hpad-red;
      font-size: 13px;
      margin-top: 6px;
      margin-left: 32px;
      width: 322px;
    }
    .sort-comment {
      font-size: 10px;
      color: $hpad-red;
      text-align: right;
      margin-top: 10px;
    }
    #user-Alert-histogram {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 10px;
      margin-bottom: 50px;
      height: 300px;
      width: 626px;
      background-color: #fff;

      .switchVisibleField {
        margin-left: 108px;
        .bgcolor-yellow {
          background-color: #c0c000;
        }
        .bgcolor-orange {
          background-color: #f09000;
        }
        .bgcolor-chocolate {
          background-color: #a04000;
        }
        .bgcolor-olive {
          background-color: #808000;
        }
        .bgcolor-gray {
          background-color: #a0a0a0;
        }
        .bgcolor-blue {
          background-color: #5876ff;
        }
        .bgcolor-brown {
          background-color: #ffb500;
        }
        .bgcolor-border {
          background: repeating-linear-gradient(
            -45deg,
            #5876ff,
            #5876ff 5px,
            #ffb500 5px,
            #ffb500 10px
          );
        }
        .switchSet {
          display: inline-block;
          margin-right: 8px;
          padding-right: 10px;
          color: #ffffff;
          font-weight: bold;
          label {
            vertical-align: middle;
          }
        }
      }
    }
  }
}
