.icon-icons_setting {
  display: grid;
  place-items: center;
  box-sizing: border-box;

  height: 2rem;

  font-size: 28px !important;

  &::before {
    content: "\E00C";
  }
}