@import "@/styles/breakpoints.scss";

#setting-sensor-tab {
  .add-new-sensor-id-form {
    display: flex;
    margin: 0 0 1rem 0;

    button {
      margin: 0 0 0 1rem;
    }
  }

  .btn {
    & > div {
      display: inline-block;
      vertical-align: middle;
    }

    &:hover {
      cursor: pointer;
    }

    .icon {
      font-size: 25px;
    }
  }

  .form-group {
    .sensor_id {
      label {
        margin-right: 12px;
      }

      .help-block {
        margin: 8px 0 0 70px;
        color: #cc0000;
      }
    }
  }

  .pager {
    width: 340px;

    @media screen and (min-width: $sm) {
      width: 740px;
    }
  }
}
