.icon-icons_sensor {
  display: grid;
  place-items: center;
  box-sizing: border-box;

  height: 2rem;
  margin: 0 2px 0 0;
  padding: 2px 14px 0 0 !important;

  font-size: 25px !important;

  &::before {
    content: "\E00F"
  }
}