@import "@/styles/scss/_base.scss";
@import "@/styles/scss/_util.scss";

#setting-account-tab {
  .rjsf {
    .form-group {
      display: flex;
      align-items: center;

      &.field {
        margin: 1rem 0 0 0;
      }

      .control-label {
        min-width: 10.5rem;
        font-size: 1rem;
      }

      .help-block {
        .notice {
          margin: 0 0 0 1rem;
          color: limegreen;
          font-weight: bold;
        }

        .error {
          color: $hpad-red;
        }
      }
    }

    // 日本語環境以外は、カナ入力項目は非表示
    .input-kana-form {
      display: none;

      // 日本語環境の場合、カナ入力項目を表示
      &.ja {
        display: flex;
      }
    }
  }

  .autocomplete-address-button {
    display: none;
    padding: 5px 1rem;
    margin: 0 0 0 1rem;

    font-size: 14px;
    border-radius: 5px;
    color: #fff;
    background-color: #f39a2b;

    // 日本語環境の場合、住所自動入力ボタンを表示
    &.ja {
      display: block;
    }
  }

  .save-button {
    margin: 2rem 0 0 0;
  }
}
