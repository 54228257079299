@charset "UTF-8";
/*
html5doctor.com Reset Stylesheet
v1.4
2009-07-27
Author: Richard Clark - http://richclarkdesign.com
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
hgroup, menu, nav, section,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, dialog, figure, footer, header,
hgroup, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

@font-face {
  font-family: 'Yu Gothic';
  src: local("Yu Gothic Medium");
  font-weight: 100; }

@font-face {
  font-family: 'Yu Gothic';
  src: local("Yu Gothic Medium");
  font-weight: 200; }

@font-face {
  font-family: 'Yu Gothic';
  src: local("Yu Gothic Medium");
  font-weight: 300; }

@font-face {
  font-family: 'Yu Gothic';
  src: local("Yu Gothic Medium");
  font-weight: 400; }

@font-face {
  font-family: 'Yu Gothic';
  src: local("Yu Gothic Bold");
  font-weight: bold; }

body {
  font-family: 'Yu Gothic', YuGothic, sans-serif; }

body {
  font-size: 14px; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%; }

input {
  padding: 10px;
  border: 1px solid #ddd; }

input[type='submit'] {
  appearance: none;
  border: 0; }

input:focus,
select:focus {
  outline: 0; }

button {
  border: none; }

a {
  text-decoration: none; }

a:active,
a:visited {
  color: #90a4ae; }

/*
 * 共通パーツ
 */
.app_header {
  min-width: 1183px;
  height: 56px;
  background: linear-gradient(to right, #f2992b, #e27130);
  justify-content: space-between;
  position: relative; }
  .app_header span {
    color: #fff; }
  .app_header .header-left {
    display: flex; }
    .app_header .header-left p {
      display: flex;
      align-items: start; }
    .app_header .header-left span {
      padding-left: 8px; }
    .app_header .header-left .logo {
      height: 32.8px;
      margin-top: 14px;
      margin-bottom: 9.2px;
      margin-left: 26px; }
    .app_header .header-left .view-time {
      white-space: nowrap;
      margin-top: 25px;
      margin-left: 50px; }
  .app_header .header-center {
    height: 56px;
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
    .app_header .header-center .menu-text {
      color: rgba(255, 255, 255, 0.5);
      font-size: 16px; }
    .app_header .header-center .menu-button > div {
      width: 144px;
      height: 56px;
      padding-top: 23.8px;
      display: flex;
      justify-content: center;
      box-sizing: border-box; }
      .app_header .header-center .menu-button > div:hover {
        background-color: #4d3830; }
    .app_header .header-center .menu-button:last-child > div {
      border: none; }
    .app_header .header-center .menu-button.active {
      border-bottom: 3.4px solid #fff; }
      .app_header .header-center .menu-button.active .menu-text {
        color: #fff; }
  .app_header .header-right {
    margin-right: 33px;
    display: flex; }
    .app_header .header-right span {
      padding: 25px 11px 0px 15px;
      white-space: nowrap; }
    .app_header .header-right .setting-button {
      padding: 22px 18px 0px;
      display: flex;
      align-items: start;
      box-sizing: border-box; }
      .app_header .header-right .setting-button:last-child > div {
        border: none; }
      .app_header .header-right .setting-button.active {
        border-bottom: 3.4px solid #fff; }
    .app_header .header-right .info-button {
      width: 30px;
      height: 30px;
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: center; }
  .app_header .icon-icons_setting2 {
    color: #fff;
    font-size: 20px; }
  .app_header .icon-icons_arrow {
    color: #fff;
    width: 16.25px;
    height: 9.38px; }

.app_menu {
  border: 1px solid #ddd;
  width: 80px;
  min-width: 80px;
  text-align: center;
  font-size: 12px;
  color: #90a4ae;
  height: 100%; }
  .app_menu .menu-button > div {
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
    text-decoration: none; }
    .app_menu .menu-button > div:hover {
      background-color: #90a4ae;
      color: #fff; }
    .app_menu .menu-button > div.active {
      background-color: #90a4ae;
      color: #fff;
      width: 80px; }
  .app_menu .menu-button:last-child > div {
    border: none; }
  .app_menu .icon {
    font-size: 38px; }

.app_loading {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex; }
  .app_loading.hide {
    display: none; }
  .app_loading > div {
    margin: auto;
    text-align: center; }
    .app_loading > div p {
      font-size: 28px;
      line-height: 1.4;
      margin-bottom: 20px; }
      .app_loading > div p span {
        font-size: 21px;
        display: block; }

.app_dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex; }
  .app_dialog.hide {
    display: none; }
  .app_dialog > .content {
    margin: auto;
    text-align: center;
    width: 80%; }
    .app_dialog > .content p {
      font-size: 28px;
      line-height: 1.4;
      margin-bottom: 20px; }
      .app_dialog > .content p span {
        font-size: 21px;
        display: block; }
  .app_dialog .choiceButtons {
    justify-content: center; }
    .app_dialog .choiceButtons div {
      margin: 0 10px;
      color: #fff;
      background-color: #90a4ae;
      padding: 20px 50px;
      border-radius: 15px;
      cursor: pointer; }
  .app_dialog .closeButton {
    position: absolute;
    display: inline-block;
    top: 0px;
    right: 0px;
    width: 64px;
    height: 64px;
    border-radius: 64px;
    border: solid 4px #90a4ae;
    background-color: white;
    margin: 0;
    padding: 0;
    transform: scale(0.5);
    cursor: pointer; }
    .app_dialog .closeButton:before {
      content: '';
      position: absolute;
      display: inline-block;
      top: 13%;
      left: 45%;
      width: 6px;
      height: 70%;
      border: 0;
      margin: 0;
      padding: 0;
      background-color: #90a4ae;
      -moz-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
    .app_dialog .closeButton:after {
      content: '';
      position: absolute;
      display: inline-block;
      top: 13%;
      left: 45%;
      width: 6px;
      height: 70%;
      border: 0;
      margin: 0;
      padding: 0;
      background-color: #90a4ae;
      -moz-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg); }
    .app_dialog .closeButton:hover {
      background-color: #90a4ae; }
      .app_dialog .closeButton:hover:before {
        background-color: white; }
      .app_dialog .closeButton:hover:after {
        background-color: white; }

.app_container {
  width: 1090px;
  margin-left: 110px; }

.home_container {
  width: 100%;
  min-width: 1183px;
  margin: 0 110px; }

@media screen and (max-width: 1439px) {
  .home_container {
    margin: 0; } }

.hpad-pulldown-1 {
  color: #000;
  display: inline-block;
  vertical-align: middle;
  position: relative; }
  .hpad-pulldown-1 > p {
    display: inline-block;
    margin-right: 10px; }
  .hpad-pulldown-1 span {
    padding-right: 10px; }
  .hpad-pulldown-1 select {
    display: inline-block;
    appearance: none;
    min-width: 200px;
    padding: 8px;
    border-radius: 0px;
    background-color: #fff;
    border: 1px solid #ddd;
    color: #000; }
  .hpad-pulldown-1::after {
    content: '▼';
    position: absolute;
    top: 12px;
    right: 10px;
    font-size: 10px;
    color: #90a4ae;
    pointer-events: none; }

.hpad-tab-1 .tab {
  color: #90a4ae;
  background-color: #fff;
  font-size: 15px;
  padding: 12px 28px;
  min-width: 120px;
  border: solid #ddd;
  border-width: 1px 1px 0px;
  justify-content: center;
  margin-right: 10px; }
  .hpad-tab-1 .tab .icon {
    font-size: 40px;
    margin-right: 5px; }
  .hpad-tab-1 .tab:hover {
    background-color: #90a4ae;
    color: #fff; }
  .hpad-tab-1 .tab.active {
    background-color: #90a4ae;
    color: #fff; }

.hpad-tab-1 .tab-content {
  border: 1px solid #ddd; }

.tab-content {
  padding: 50px; }

.hpad-form-1 input {
  font-size: 14px; }

.hpad-form-1 input[disabled] {
  background-color: #eee;
  color: #666; }

.hpad-form-1 button[type='submit'] {
  padding: 10px 20px;
  background-color: #f39a2b;
  color: #fff;
  border-radius: 10px; }

.hpad-form-1 button[type='button'] {
  padding: 10px 20px;
  background-color: #f39a2b;
  color: #fff;
  border-radius: 10px; }

.hpad-form-1 .rjsf .field {
  display: flex;
  align-items: center; }
  .hpad-form-1 .rjsf .field .control-label {
    min-width: 138px;
    font-size: 13px; }

.hpad-form-1 .rjsf fieldset > div {
  margin-top: 15px; }

.hpad-form-1 .rjsf fieldset > div:first-child {
  margin-top: 0px; }

.hpad-form-1 .form-pulldown {
  position: relative; }
  .hpad-form-1 .form-pulldown select {
    display: inline-block;
    appearance: none;
    min-width: 200px;
    padding: 8px;
    border-radius: 0px;
    background-color: #fff;
    border: 1px solid #ddd;
    color: #000; }
  .hpad-form-1 .form-pulldown::after {
    content: '▼';
    position: absolute;
    top: 12px;
    right: 10px;
    font-size: 10px;
    color: #90a4ae;
    pointer-events: none; }

.react-autosuggest__container {
  position: relative; }

.react-autosuggest__input--focused {
  outline: none; }

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.react-autosuggest__suggestions-container {
  display: none; }

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 38px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 100px;
  overflow-y: auto;
  z-index: 2; }

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px; }

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd; }

.tooltip {
  position: absolute;
  background-color: #ffffff;
  border: solid 1px #000000;
  padding: 0.2rem;
  z-index: 10; }

.tooltip-parent {
  position: relative; }
  .tooltip-parent .tooltip {
    font-size: 1.2rem;
    transition: opacity 0.2s, visibility 0.2s;
    opacity: 0;
    visibility: hidden;
    white-space: nowrap;
    position: absolute;
    padding: 0.1rem 0.3rem;
    bottom: -1.8rem;
    border-radius: 0.2rem;
    background-color: #ffffff;
    border: solid 1px #000000;
    z-index: 999; }

.tooltip-parent:hover .tooltip {
  opacity: 1;
  visibility: visible; }

@font-face {
  font-family: "icon";
  src: url("../fonts/icon.eot");
  src: url("../fonts/icon.eot?#iefix") format("embedded-opentype"), url("../fonts/icon.woff") format("woff"), url("../fonts/icon.ttf") format("truetype"), url("../fonts/icon.svg#icon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  font-family: "icon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-icons_add_person:before {
  content: "\E001"; }

.icon-icons_bell:before {
  content: "\E002"; }

.icon-icons_check:before {
  content: "\E003"; }

.icon-icons_delete:before {
  content: "\E005"; }

.icon-icons_laxative_note:before {
  content: "\E006";
  font-size: 1em; }

.icon-icons_edit:before {
  content: "\E007"; }

.icon-icons_edit_2:before {
  content: "\E008"; }

.icon-icons_home:before {
  content: "\E009"; }

.icon-icons_person:before {
  content: "\E00A"; }

.icon-icons_search:before {
  content: "\E00B"; }

.icon-icons_wet:before {
  content: "\E00E"; }

.icon-icons_hospital:before {
  content: "\E010"; }

.icon-icons_home2:before {
  content: "\E011"; }

.icon-icons_setting2:before {
  content: "\E012"; }

.icon-icons_arrow:before {
  content: "\E013"; }

.icon-icons_constipation:before {
  content: "\E014"; }

.icon-icons_constipation_note:before {
  content: "\E014";
  font-size: 1em; }

.icon-icons_loadtime:before {
  content: "\E015"; }

.icon-icons_calendar:before {
  content: "\E016"; }

.icon-icons_calendar_note:before {
  content: "\E016";
  font-size: 1em; }

.icon-connection_error .path1:before {
  content: "\e900";
  color: #90a4ad; }

.icon-connection_error .path2:before {
  content: "\e901";
  margin-left: -1.25em;
  color: #f0002d; }

.icon-connection_error .path3:before {
  content: "\e902";
  margin-left: -1.25em;
  color: #f0002d; }

.icon-gyro_error .path1:before {
  content: "\e903";
  color: #90a4ad; }

.icon-gyro_error .path2:before {
  content: "\e904";
  margin-left: -1.25em;
  color: #90a4ae;
  opacity: 0.9988; }

.icon-gyro_error .path3:before {
  content: "\e905";
  margin-left: -1.25em;
  color: black; }

.icon-gyro_error .path4:before {
  content: "\e906";
  margin-left: -1.25em;
  color: black; }

.icon-gyro_error .path5:before {
  content: "\e907";
  margin-left: -1.25em;
  color: #f0002d; }

.icon-gyro_error .path6:before {
  content: "\e908";
  margin-left: -1.25em;
  color: #f0002d; }

.icon-HW_error .path1:before {
  content: "\e909";
  color: #90a4ae; }

.icon-HW_error .path2:before {
  content: "\e90a";
  margin-left: -1.25em;
  color: #90a4ae;
  opacity: 0.9988; }

.icon-HW_error .path3:before {
  content: "\e90b";
  margin-left: -1.25em;
  color: #f0002d; }

.icon-HW_error .path4:before {
  content: "\e90c";
  margin-left: -1.25em;
  color: #f0002d; }

.icon-water_error .path1:before {
  content: "\e90d";
  color: #90a4ae; }

.icon-water_error .path2:before {
  content: "\e90e";
  margin-left: -1.25em;
  color: #90a4ae;
  opacity: 0.9988; }

.icon-water_error .path3:before {
  content: "\e90f";
  margin-left: -1.25em;
  color: #3625ff; }

.icon-disconnected_error .path1:before {
  content: "\e910";
  color: #90a4ad; }

.clearfix:after {
  content: " ";
  display: block;
  clear: both; }

.flex {
  display: flex; }

.align-center {
  align-items: center; }

.align-start {
  align-items: start; }

.flex-wrap {
  flex-wrap: wrap; }

.space-between {
  justify-content: space-between; }

.justify-center {
  justify-content: center; }

.flex-start {
  justify-content: flex-start; }

.text-center {
  text-align: center; }

.mr20 {
  margin-right: 20px; }

.mr30 {
  margin-right: 30px; }

.mb10 {
  margin-bottom: 10px; }

.mb20 {
  margin-bottom: 20px; }

.mb30 {
  margin-bottom: 30px; }

.mt10 {
  margin-top: 10px; }

.mt20 {
  margin-top: 20px; }

.mt30 {
  margin-top: 30px; }

.font-hpad-gray {
  color: #90a4ae; }

.font-hpad-red {
  color: #cc0000; }

.app_menu .menu-button > div:hover, .hpad-tab-1 .tab:hover {
  transition: all 0.25s cubic-bezier(0.22, 0.61, 0.36, 1); }

#login .loginForm {
  width: 280px;
  margin: 0 auto;
  text-align: center;
  margin-top: 100px; }
  #login .loginForm .title {
    font-size: 20px;
    margin-bottom: 20px; }
  #login .loginForm .control-label {
    display: none; }
  #login .loginForm .form-control {
    margin-bottom: 10px;
    width: 100%; }
  #login .loginForm button[type=submit] {
    margin-top: 10px; }
  #login .loginForm .hpad-form-1 .rjsf .field {
    display: block; }
  #login .loginForm .error {
    color: #cc0000; }
  #login .loginForm .eye-image {
    position: relative;
    top: -92px;
    left: 183px; }

/*
 * 利用者情報個別スタイル
 */
#users .title {
  font-size: 21px;
  color: #90a4ae;
  font-weight: bold; }

#users .add_btn {
  background-color: #f39a2b;
  border-radius: 8px;
  color: #fff;
  padding: 4px 11px 4px 8px;
  margin-left: 20px; }
  #users .add_btn > div {
    vertical-align: middle;
    display: inline-block; }
  #users .add_btn .icon {
    font-size: 25px; }

#users .search-box {
  position: relative; }
  #users .search-box input {
    padding-left: 30px; }
  #users .search-box .icon {
    position: absolute;
    top: 8px;
    left: 7px;
    color: #90a4ae;
    font-size: 20px; }

#users .loadingView {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 200px;
  height: 32px;
  margin: auto;
  text-align: center;
  font-size: 24px;
  color: #90a4ae; }

#users .no-data {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 400px;
  height: 100px;
  margin: auto;
  text-align: center; }
  #users .no-data p {
    font-size: 24px;
    padding-bottom: 0px;
    color: #90a4ae; }
  #users .no-data .center_add_btn {
    width: 100%;
    margin-top: 20px; }
    #users .no-data .center_add_btn div {
      display: inline-block;
      background-color: #f39a2b;
      border-radius: 10px;
      color: #fff;
      padding: 10px 20px;
      width: 120px; }

#users .user-box {
  width: 310px;
  height: 125px;
  padding: 15px 20px 20px;
  border: 1px solid #ddd;
  margin-bottom: 20px; }
  #users .user-box .box-title {
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 12px; }
  #users .user-box .user-status {
    font-size: 15px; }
    #users .user-box .user-status .icon {
      font-size: 25px;
      color: #90a4ae; }
    #users .user-box .user-status .user-data {
      font-size: 12px; }
  #users .user-box .user-name {
    font-size: 18px; }
    #users .user-box .user-name span {
      padding-left: 5px;
      font-size: 13px; }
  #users .user-box .log-btn {
    background-color: #90a4ae;
    padding: 10px 28px;
    border-radius: 8px;
    display: inline-block;
    color: #fff; }

#users .users-box {
  min-width: 1060px;
  max-width: 1060px; }

/*
 * 利用者作成・編集スタイル
 */
#userEdit .title {
  font-size: 24px;
  font-weight: bold; }

#userEdit .hpad-form-1 {
  position: relative; }
  #userEdit .hpad-form-1 .rjsf .field input {
    width: 178px;
    font-size: 18px; }
  #userEdit .hpad-form-1 .rjsf .field select {
    font-size: 18px; }
  #userEdit .hpad-form-1 .rjsf .field .help-block {
    width: 0px;
    white-space: nowrap; }
  #userEdit .hpad-form-1 .rjsf .field .control-label {
    font-size: 18px;
    font-weight: bold; }
  #userEdit .hpad-form-1 .rjsf .field .form-pulldown::after {
    content: '▼';
    position: absolute;
    top: 19px;
    right: 10px;
    font-size: 10px;
    color: #90a4ae;
    pointer-events: none; }
  #userEdit .hpad-form-1 .rjsf .field .hpad-pulldown-1 p {
    font-size: 18px;
    font-weight: bold;
    width: 128px; }
  #userEdit .hpad-form-1 .rjsf .field .hpad-pulldown-1::after {
    content: '▼';
    position: absolute;
    top: 19px;
    right: 10px;
    font-size: 10px;
    color: #90a4ae;
    pointer-events: none; }
  #userEdit .hpad-form-1 .rjsf .field .hpad-datalist-1 {
    display: flex;
    align-items: center; }
  #userEdit .hpad-form-1 .rjsf .btn-info {
    font-size: 18px; }
  #userEdit .hpad-form-1 .error {
    color: #cc0000; }
  #userEdit .hpad-form-1 .add-group-button {
    color: #90a4ae;
    font-size: 18px;
    font-weight: bold;
    margin-left: 10px;
    cursor: pointer; }
    #userEdit .hpad-form-1 .add-group-button:hover {
      border-bottom: 1px solid #90a4ae; }

#userEdit .setting-group-dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex; }
  #userEdit .setting-group-dialog.hide {
    display: none; }
  #userEdit .setting-group-dialog > .content {
    margin: auto;
    text-align: center;
    color: black;
    background-color: white;
    border: solid 1px #ddd;
    border-radius: 4px;
    width: 600px;
    padding: 10px;
    z-index: 10001;
    position: relative; }
  #userEdit .setting-group-dialog .rjsf fieldset .form-group {
    line-height: 88px; }
    #userEdit .setting-group-dialog .rjsf fieldset .form-group::after {
      clear: both; }
    #userEdit .setting-group-dialog .rjsf fieldset .form-group div {
      display: inline-block; }
    #userEdit .setting-group-dialog .rjsf fieldset .form-group div.help-block {
      display: block;
      text-align: left;
      margin-left: 86px;
      line-height: 20px; }
      #userEdit .setting-group-dialog .rjsf fieldset .form-group div.help-block .error {
        color: #cc0000; }
    #userEdit .setting-group-dialog .rjsf fieldset .form-group .react-autosuggest__input {
      font-size: 24px;
      height: 60px; }
    #userEdit .setting-group-dialog .rjsf fieldset .form-group .react-autosuggest__suggestions-container {
      font-size: 24px;
      display: block; }
    #userEdit .setting-group-dialog .rjsf fieldset .form-group .react-autosuggest__suggestion {
      line-height: 20px; }
  #userEdit .setting-group-dialog .rjsf label {
    font-size: 24px;
    display: inline-block;
    width: 230px; }
    #userEdit .setting-group-dialog .rjsf label span {
      color: #cc0000; }
  #userEdit .setting-group-dialog .rjsf input {
    height: 60px;
    font-size: 24px;
    min-width: 300px; }
  #userEdit .setting-group-dialog .rjsf button {
    width: 90px;
    height: 50px;
    font-size: 24px;
    clear: both; }
  #userEdit .setting-group-dialog .rjsf :disabled {
    background-color: #ddd; }
  #userEdit .setting-group-dialog .errorField {
    width: 100%;
    font-size: 27px;
    color: red; }
  #userEdit .setting-group-dialog .groupDialogTitle {
    font-size: 35px;
    border-bottom: 1px solid gainsboro !important;
    margin: 10px 50px;
    padding-bottom: 5px; }
  #userEdit .setting-group-dialog .groupList .groupListTitle {
    font-size: 35px;
    border-bottom: 1px solid gainsboro !important;
    margin: 10px 50px;
    padding-bottom: 5px; }
  #userEdit .setting-group-dialog .groupList .groupDataField {
    height: 150px;
    max-height: 150px;
    overflow-y: auto; }
    #userEdit .setting-group-dialog .groupList .groupDataField .groupData {
      font-size: 27px;
      padding: 2px 0; }
    #userEdit .setting-group-dialog .groupList .groupDataField .noGroupData {
      font-size: 27px;
      padding: 2px 0; }
  #userEdit .setting-group-dialog .btn {
    margin-top: 20px; }
  #userEdit .setting-group-dialog .close-btn {
    position: absolute;
    display: inline-block;
    top: 0px;
    right: 0px;
    width: 68px;
    height: 68px;
    border-radius: 64px;
    border: solid 4px #90a4ae;
    background-color: white;
    margin: 0;
    padding: 0;
    transform: scale(0.5);
    cursor: pointer; }
    #userEdit .setting-group-dialog .close-btn::before {
      content: '';
      position: absolute;
      display: inline-block;
      top: 14%;
      left: 44%;
      width: 6px;
      height: 70%;
      border: 0;
      margin: 0;
      padding: 0;
      background-color: #90a4ae;
      -moz-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
    #userEdit .setting-group-dialog .close-btn::after {
      content: '';
      position: absolute;
      display: inline-block;
      top: 14%;
      left: 44%;
      width: 6px;
      height: 70%;
      border: 0;
      margin: 0;
      padding: 0;
      background-color: #90a4ae;
      -moz-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg); }
    #userEdit .setting-group-dialog .close-btn:hover {
      background-color: #90a4ae; }
      #userEdit .setting-group-dialog .close-btn:hover:before {
        background-color: white; }
      #userEdit .setting-group-dialog .close-btn:hover:after {
        background-color: white; }

/*
 * ページャースタイル
 */
.pager {
  width: 100%;
  text-align: center; }
  .pager ul {
    margin: 40px 0; }
    .pager ul li {
      min-width: 20px;
      display: inline-block;
      text-align: center;
      padding: 6px 12px;
      background-color: #fff;
      border-left: 1px solid #ddd;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd; }
    .pager ul li:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }
    .pager ul li:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 1px solid #ddd; }
  .pager .prevPages:hover,
  .pager .prev:hover,
  .pager .page:hover,
  .pager .next:hover,
  .pager .nextPages:hover {
    cursor: pointer;
    color: #f39a2b;
    background-color: #ffecac;
    border-color: #ddd; }
  .pager .current {
    color: #fff;
    background-color: #f39a2b;
    border-color: #f39a2b; }

.datetie-picker-for-excretion-results > div {
  position: initial !important; }

/*
 * 日時フィールドスタイル
 */
.datetime-picker {
  position: relative; }
  .datetime-picker .with-time {
    width: 320px; }
  .datetime-picker .without-time {
    width: 200px; }
  .datetime-picker > div {
    position: absolute;
    padding: 4px;
    top: 43px;
    left: 150px;
    border: 1px solid #eeeeee;
    background-color: white;
    z-index: 1; }
  .datetime-picker .toolbar {
    text-align: center;
    line-height: 24px; }
    .datetime-picker .toolbar .prev-month-btn {
      float: left;
      cursor: pointer;
      margin-top: 4px;
      width: 8px;
      height: 8px;
      box-sizing: border-box;
      border: 8px solid transparent;
      border-right: 8px solid #999; }
      .datetime-picker .toolbar .prev-month-btn:hover {
        border-right: 8px solid rgba(9, 9, 9, 0.2); }
    .datetime-picker .toolbar .current-month {
      float: left;
      width: 82%;
      text-align: center; }
    .datetime-picker .toolbar .next-month-btn {
      float: left;
      cursor: pointer;
      margin-top: 4px;
      width: 8px;
      height: 8px;
      box-sizing: border-box;
      border: 8px solid transparent;
      border-left: 8px solid #999; }
      .datetime-picker .toolbar .next-month-btn:hover {
        border-left: 8px solid rgba(9, 9, 9, 0.2); }
    .datetime-picker .toolbar .not-allowed {
      cursor: not-allowed; }
  .datetime-picker .clear-float {
    float: initial !important; }
  .datetime-picker .calendar {
    float: left;
    line-height: 24px; }
    .datetime-picker .calendar table {
      width: 200px; }
    .datetime-picker .calendar th {
      padding-top: 4px;
      text-align: center; }
    .datetime-picker .calendar td {
      text-align: center; }
      .datetime-picker .calendar td:hover:not(.not-allowed) {
        border-radius: 0.3rem;
        background-color: #ffecac;
        color: #f39a2b;
        filter: alpha(opacity=50);
        -moz-opacity: 0.5;
        opacity: 0.5; }
    .datetime-picker .calendar td.selected {
      border-radius: 0.3rem;
      background-color: #f39a2b;
      color: #fff; }
    .datetime-picker .calendar .sun {
      color: red; }
    .datetime-picker .calendar .sat {
      color: blue; }
    .datetime-picker .calendar .out-of-date {
      background-color: white;
      filter: alpha(opacity=50);
      -moz-opacity: 0.5;
      opacity: 0.5; }
    .datetime-picker .calendar td.not-allowed {
      cursor: not-allowed; }
  .datetime-picker .time {
    text-align: center;
    float: left;
    margin-left: 24px;
    margin-top: -5px; }
    .datetime-picker .time > div {
      float: left; }
    .datetime-picker .time .hours > div {
      line-height: 40px; }
    .datetime-picker .time .hour {
      font-size: 18px;
      width: 24px; }
    .datetime-picker .time .time-separator {
      line-height: 78px;
      width: 30px; }
    .datetime-picker .time .minutes > div {
      line-height: 40px; }
    .datetime-picker .time .minute {
      font-size: 18px;
      width: 24px; }
    .datetime-picker .time .up-btn {
      margin-left: 2px;
      width: 10px;
      height: 10px;
      box-sizing: border-box;
      border: 10px solid transparent;
      border-bottom: 10px solid #999; }
      .datetime-picker .time .up-btn:hover {
        border-bottom: 10px solid rgba(9, 9, 9, 0.2); }
    .datetime-picker .time .down-btn {
      margin-left: 2px;
      width: 10px;
      height: 10px;
      box-sizing: border-box;
      border: 10px solid transparent;
      border-top: 10px solid #999; }
      .datetime-picker .time .down-btn:hover {
        border-top: 10px solid rgba(9, 9, 9, 0.2); }
