.icon-icons_check_person {
  display: grid;
  place-items: center;
  box-sizing: border-box;

  height: 2rem;
  margin: 0 2px 0 0;

  font-size: 28px !important;

  &::before {
    content: "\E004"
  }
}