@import "@/styles/scss/_base.scss";
@import "@/styles/scss/_util.scss";

/*
 * トップページ個別スタイル
 */
 #app {

  .sub-header {
    position: relative;
    justify-content: space-between;

    width: 100%;
    // TODO:
    // サブヘッダーの左の空白を padding で指定しているが、
    // #app と .sub-header の間に要素を作成して max-width を持たせるほうが良い
    padding: 2rem 0 0 118px;

    background: #fafafa;
  }

  .show-name {
    display: inline-block;
    margin-left: 20px;

    input[type="checkbox"] {
      -moz-transform: scale(1.2);
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }

    .show-name-text {
      display: inline-block;
      vertical-align: middle;

      margin: 0 0 0 5px;

      font-size: 14px;
      font-family: "Noto Sans";
    }

    .reload-button {
      padding: 0;
      margin: 0 0 0 1rem;
      background-color: #FFF;

      img {
        width: 1.2rem;
      }
    }
  }

  .flex-between {
    display: flex;
    justify-content: space-between;
  }

  .note-space {
    display: inline-block;
    margin: auto 0.5rem;

    .notes {
      display: inline-block;
      font-size: 14px;

      .note {
        cursor: default;
        margin: 0 0 0 0.5rem;
      }

      .note::before {
        content: "■";
        text-shadow: 1px 1px 0 $hpad-gray, -1px 1px 0 $hpad-gray, 1px -1px 0 $hpad-gray, -1px -1px 0 $hpad-gray;
      }

      .note-top-priority::before {
        color: #febdb2;
        margin: 0 5px 0 0;
      }

      .note-top-priority:hover {
        background-color: rgba(#febdb2, 0.5);
      }

      .note-excretion-forecast::before {
        color: #ccffcc;
        margin: 0 5px 0 0;
      }

      .note-excretion-forecast:hover {
        background-color: rgba(#ccffcc, 0.5);
      }

      .note-high-priority::before {
        color: #fefeb2;
        margin: 0 5px 0 0;
      }

      .note-high-priority:hover {
        background-color: rgba(#fefeb2, 0.5);
      }

      .note-normal::before {
        color: #fafafa;
        margin: 0 5px 0 0;
      }
    }
  }

  /* ツールチップ → TODO: Tooltip コンポーネントに統合したい */
  // Tooltip コンポーネントと、表示非表示の切替方法が異なる。
  // こっちは opacity と visibility で制御しようとしている。
  // Tooltip コンポーネントとして統合する or MUI を利用する
  .__tooltip {
    position: absolute;

    // デフォルト値
    top: 1.5rem;
    left: -50%;

    max-width: 26rem;
    line-height: 1.3rem;
    font-size: 14px;
    padding: 0.2rem;
    z-index: 10;

    background-color: #ffffff;
    color: #565656;
    border: solid 1px #565656;
  }

  .__tooltip-parent {
    position: relative;

    .__tooltip {
      position: absolute;

      transition: opacity 0.2s, visibility 0.2s;
      opacity: 0;
      visibility: hidden;

      padding: 5px 10px;
      z-index: 999;

      border-radius: 5px;
    }
  }

  .__tooltip-parent:hover {
    .__tooltip {
      opacity: 1;
      visibility: visible;
    }
  }

  /* 通知フィルター */
  .notification-filter-dropbox {
    select {
      padding: 0.5rem 1.5rem 0.5rem 0.5rem;
    }
  }

  .loadingView {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width : 200px;
    height: 32px;
    margin: auto;
    text-align: center;
    font-size: 24px;
    color: $hpad-gray;
  }

  .no-data {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width : 400px;
    height: 100px;
    margin: auto;
    text-align: center;
    p {
      font-size: 24px;
      padding-bottom: 0px;
      color: $hpad-gray;
    }

    // 新規追加ボタン
    .add_btn {
      width: 100%;
      margin-top: 20px;
      div {
        display: inline-block;
        background-color: $hpad-orange;
        border-radius: 10px;
        color: $hpad-white;
        padding: 10px 20px;
        width: 120px;
      }
    }

    .user-table {
      display: none;
    }
  }

  // テーブルスタイル
  .user-table {
    width: 100%;
    min-width: 1183px;

    table {
      width: 100%;
    }

    tr:hover {
      @extend %hover-animation;
      background-color: #e0f2f1;
    }

    .user-table-content {
      min-width: 1183px;
      .data_row {
        border-bottom: 1px solid #E0E0E0;
        // データスタイル
        .room_careperson {
          width: 78%;
          .individual {
            padding: 10px 60px;
            min-width: 580px;
            .place_card {
              display: inline-block;
              position: relative;
              min-width: 112px;
              height: 64px;
              background: transparent;
              border-radius: 10px;
              border: 1px solid #D7D7D7;
              p {
                position: absolute;
                top:  50%;
                left: 50%;
                -webkit-transform : translate(-50%,-50%);
                transform : translate(-50%,-50%);
                text-align: center;
                font-family: Roboto;
                font-weight: bold;
                font-size: 32px;
                color: black;
                white-space: nowrap;
              }
              .font_size_adjuster {
                font-size: 18px;
              }
            }

            .name_card {
              display: inline-block;
              position: relative;
              min-width: 450px;
              height: 64px;
              z-index: 1;

              span {
                position: absolute;
                top:  50%;
                left: 5%;
                -webkit-transform : translate(0,-50%);
                transform : translate(0,-50%);
                text-align: center;
                font-family: Roboto;
                font-weight: bold;
                font-size: 26px;
                color: black;
                white-space: nowrap;

                &.hide {
                  visibility: hidden;
                }

                .high_priority_alert {
                  position: relative;
                  display: inline-block;
                  font-size: 3rem;
                  visibility: visible !important;
                  vertical-align: middle;

                  &.hide {
                    left: 0px;
                  }
                }
              }
            }
          }
        }

        .alert_date {
          position: relative;
          width: 22%;

          span {
            position: absolute;
            top:  50%;
            left: 50%;
            -webkit-transform : translate(-50%,-50%);
            transform : translate(-50%,-50%);
            text-align: center;
            font-family: Roboto;
            font-weight: bold;
            font-size: 32px;
            white-space: nowrap;

            .blank {
              width: 40px;
              height: 0px;
              border: 2px solid black;
              margin: -3px -15px;
            }
          }
        }

        /* 点滅 */
        .blinking{
          -webkit-animation:blink 1.0s ease-in-out infinite alternate;
            -moz-animation:blink 1.0s ease-in-out infinite alternate;
            animation:blink 1.0s ease-in-out infinite alternate;
        }

        @-webkit-keyframes blink{
            0% {opacity:0;}
            100% {opacity:1;}
        }

        @-moz-keyframes blink{
            0% {opacity:0;}
            100% {opacity:1;}
        }

        @keyframes blink{
            0% {opacity:0;}
            100% {opacity:1;}
        }
      }

      // エラーアイコン
      .error_td {
        width: 1%;

        .error-icon {
          position: absolute;
          left: 800px;
          font-size: 60px;
          display: flex;
          -webkit-transform : translate(0,25%);
          transform : translate(0,25%);
          pointer-events: none;

          .error_msg {
            padding-left: 10px;

            font-family: "Noto Sans";
            font-size: 27px;
            font-weight: bold;
            // white-space: nowrap;
          }

          .hw_error, .water_error {
            margin: -12px 0;
          }

          .disconnected_error {
            -webkit-transform : translate(5%, 0%);
            transform : translate(5%, 0%);
          }
        }
      }

    }

    .no_need_to_action {
      font-size: 2em;
      position: absolute;
      margin: 11% 30%;
      white-space: nowrap;
    }
  }

  .balloon-target {
    position:relative;

    .balloon {
      display: none;
    }

    &:hover {
      .balloon {
        position: absolute;
        top:18px;
        display: inline-block;
        margin: 1.5em 0;
        padding: 6px 6px;
        min-width: 150px;
        max-width: 100%;
        color: #000;
        font-size: 12px;
        background: #FFF;
        border: solid 3px $hpad-gray;
        box-sizing: border-box;
        border-radius: 8px;
        z-index: 10000;

        p {
          margin: 0;
          padding: 0;
        }
      }
      .balloon-left {
        left:0px;
        &:before {
          content: "";
          position: absolute;
          top: -24px;
          left: 30px;
          margin-left: -15px;
          border: 12px solid transparent;
          border-bottom: 12px solid #FFF;
          z-index: 10002;
        }
        &:after {
          content: "";
          position: absolute;
          top: -30px;
          left: 30px;
          margin-left: -17px;
          border: 14px solid transparent;
          border-bottom: 14px solid $hpad-gray;
          z-index: 10001;
        }
      }
      .balloon-right {
        right:0px;
        &:before {
          content: "";
          position: absolute;
          top: -24px;
          right: 30px;
          margin-left: -15px;
          border: 12px solid transparent;
          border-bottom: 12px solid #FFF;
          z-index: 10002;
        }
        &:after {
          content: "";
          position: absolute;
          top: -30px;
          right: 28px;
          margin-left: -17px;
          border: 14px solid transparent;
          border-bottom: 14px solid $hpad-gray;
          z-index: 10001;
        }
      }
    }
  }

  .priority-yellow {
    background-color: #FEFEB2;
  }

  .priority-green {
    background-color: #CCFFCC;
  }

  .priority-red {
    background-color: #FEBDB2;
  }

  @media screen and (min-width: 1440px) and (max-width: 1919px) {
    // テーブルスタイル
    .user-table {
      .room_careperson_th {
        padding-left: 100px;
        padding-right: 5px;
      }

      .user-table-content {
        .data_row {
          // データスタイル
          .room_careperson {
            width: 75%;
            .individual {
              padding: 10px 20px;
              min-width: 480px;
              .name_card {
                min-width: 360px;
                span {
                  font-size: 22px;
                }
              }
            }
          }

          .alert_date {
            width: 25%;

            span {
              font-size: 24px;

              .blank {
                width: 30px;
              }
            }
          }
        }
        .error_td {
          // エラーアイコン
          .error-icon {
            left: 625px;
            font-size: 40px;
            -webkit-transform : translate(0, 50%);
            transform : translate(0, 50%);
            .error_msg {
              font-size: 22px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width:1439px) {
    // テーブルスタイル
    .user-table {
      .room_careperson_th {
        padding-left: 100px;
        padding-right: 5px;
      }
      .user-table-content {
        .data_row {
          // データスタイル
          .room_careperson {
            width: 75%;
            .individual {
              padding: 10px 20px;
              min-width: 480px;
              .name_card {
                min-width: 360px;
                span {
                  font-size: 20px;
                }
              }
            }
          }

          .alert_date {
            width: 25%;

            span {
              font-size: 24px;

              .blank {
                width: 30px;
              }
            }
          }
        }
        .error_td {
          // エラーアイコン
          .error-icon {
            left: 525px;
            font-size: 40px;
            -webkit-transform : translate(0, 50%);
            transform : translate(0, 50%);
            .error_msg {
              font-size: 22px;
            }
          }
        }
      }
    }
  }

  .alert-notification-text {
    display: flex;
    justify-content: flex-start;
    font-weight:  bold;
    margin-bottom: 30px;

    &.not-show {
      display: none;
    }

    p {
      color:#FF7A01;
      font-size: 1.2rem;
    }
  }
}
