.notification-counter {
  position: relative;
  display: inline-block;

  padding: 0 0.5rem 5px 0.5rem;

  background: inherit;
  border-bottom: 4px solid #ff7a01;
  font-size: 1rem;

  .waiting_for_action {
    position: inherit;
    display: inline-block;
    font-family: "Noto Sans";
    text-align: left;
    color: #000;
  }

  .counter {
    position: inherit;
    display: inline-block;

    padding: 0 1rem;

    font-family: "Noto Sans";
    font-size: 2.5rem;
    font-weight: bold;
    color: #000;
  }

  .person {
    position: inherit;
    display: inline-block;
    font-family: "Noto Sans";
    color: #000;
  }
}
