@import "@/styles/scss/_base.scss";
@import "@/styles/scss/_util.scss";

.histogram-duration-selector {

  // TODO: 暫定対応
  .locale-en {
    #root {
      .field:first-child {
        &:before {
          content: "Period";
          margin: 0 5px 0 0;
        }

        &:after {
          content: "～";
          margin: 0 5px 0 5px;
        }
      }
    }
  }

  #root {
    display: flex;

    .field:first-child {
      &:before {
        content: "期間";
        margin: 0 5px 0 0;
      }

      &:after {
        content: "～";
        margin: 0 5px 0 5px;
      }
    }
  }

  input[type=date] {
    width: 140px;
    padding: 5px;
    font-size: 15px;
  }

  .rjsf {
    display: flex;

    button {
      cursor: pointer;

      margin: 4px 0 4px 8px;
      padding: 3px 10px;
      border-radius: 5px;

      background-color: $hpad-orange;
      color: $hpad-white;

      &:disabled {
        background-color: $hpad-gray;
        cursor: default;
      }
    }
  }

  // .hpad-form-1 {

  //   .field-object {
  //     float: left;
  //   }

  //   // TODO: 暫定対応
  //   .locale-en {
  //     fieldset .field:first-child {
  //       &:before {
  //         content: "Period";
  //       }

  //       &:after {
  //         content: "～";
  //       }
  //     }
  //   }

  //   fieldset {
  //     &>div {
  //       margin-top: 4px;
  //     }

  //     .field:first-child {
  //       float: left;

  //       &:before {
  //         content: "期\00A0";
  //       }

  //       &:after {
  //         content: "\00A0～\00A0";
  //       }
  //     }

  //     input[type=date] {
  //       width: 140px;
  //       padding: 5px;
  //       font-size: 15px;
  //     }
  //   }

}
