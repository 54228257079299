@import '@/styles/scss/_base.scss';
@import '@/styles/scss/_util.scss';

// ダイアログ画面
.excretion-dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 20;
  display: flex;

  &.hide {
    display: none;
  }

  & > .content {
    position: relative;
    box-sizing: border-box;
    text-align: left;
    width: 641px;
    padding: 1.5rem 2.5rem 2rem;
    margin: 2rem auto auto;

    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
      rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
  }

  .title {
    font-size: 21px;
    line-height: 1.4;
    font-weight: bold;
  }

  .care-subject-info {
    display: flex;
    box-sizing: border-box;

    margin: 0 0 1rem 0;
    font-size: 20px;
    font-weight: bold;

    div {
      padding: 5px 0px;
      margin-right: 10px;
    }
  }

  /**
   * 記録種別の切り替え用タブ
   */
  .record-tab-container {
    margin: 0 0 1rem 0;

    .horizontal-bar {
      border-top: 1px solid #dedede;
    }

    .form-record-tab {
      width: 100%;
      pointer-events: auto;

      span {
        font-size: 1rem;
        font-weight: bold;
      }

      .field-radio-group {
        width: 472px;
        display: flex;
        margin-bottom: -1px;

        .radio-inline {
          display: flex;
          justify-content: center;

          padding: 12px 13px 10px;
          border: 1px solid #dedede;
          border-radius: 5px;
          background-color: #fff;
          color: #ff7a01;
          white-space: nowrap;

          span {
            height: 100%;
            display: flex;
            justify-content: center;
          }

          &:hover {
            @extend %hover-animation;
            background-color: #ff7a01;
            color: #fff;
          }
        }
      }

      input[type='radio'] {
        display: none;

        // タブがホバーされたとき
        & + span:hover {
          @extend %hover-animation;
          border-bottom: 2px solid #fff !important;
          background-color: #ff7a01;
          color: #fff;
        }
      }

      // 選択されているタブ
      input[type='radio']:checked + span {
        border-bottom: 2px solid #ff7a01;
      }
    }
  }

  /**
   * 記録追加ボタン
   */
  .add-field {
    position: absolute;
    margin: -5px 500px;
    white-space: nowrap;

    .add-icon {
      display: inline-block;
      padding: 4px 4px 4px 5px;
      border-radius: 12px;
      background-color: $hpad-orange;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
    }

    .disabled-color {
      background-color: #ddd !important;
    }

    .add-btn {
      display: inline-block;
      color: #565656;
      background: transparent;
      font-size: 14px;
    }
  }

  /**
   * 排泄記録タブのボディ
   * TODO: [WIP] 下剤タブのスタイルと分離
   */
  .excretion-note-container {
    .form-group.field {
      & > label {
        box-sizing: border-box;
        font-size: 1rem;
        font-weight: normal;
      }

      .radio-inline span span {
        font-size: 15px;
        font-weight: normal;
      }
    }

    .form-excretionDataSelectTab {
      width: 100%;
      pointer-events: auto;
      border-bottom: 1px solid #dedede;

      .field-radio-group {
        width: 80%;
        display: flex;
        padding-bottom: 4px;

        .radio-inline {
          width: 20%;
          display: flex;
          justify-content: center;

          span {
            width: 100%;
            height: 100%;
            padding-bottom: 2px;
            display: flex;
            justify-content: center;
          }
        }
      }

      input[type='radio'] {
        display: none;
      }

      input[type='radio']:checked + span {
        font-weight: bold;
        border-bottom: 2px solid #ff7a01;
      }
    }

    .form-alert {
      & > label {
        display: inline-block;
        width: 7rem;
        min-width: 7rem;
        padding: 0 1rem 0 1rem;
        margin: 0;
      }

      ul {
        display: inline-block;
        list-style: none;
        li {
          display: inline-block;
          //alt-datetimeは年から秒まで全て含んでいるため、時間、分以外は非表示にする
          #root_confirmed_year,
          #root_dosage_date_year {
            width: 65px;
            padding-left: 5px;
          }
          #root_confirmed_month,
          #root_dosage_date_month,
          #root_confirmed_day,
          #root_dosage_date_day,
          #root_confirmed_hour,
          #root_dosage_date_hour,
          #root_confirmed_minute,
          #root_dosage_date_minute {
            width: 50px;
            padding-left: 5px;
          }
          #root_confirmed_second,
          #root_dosage_date_second {
            display: none;
          }
          select {
            line-height: 32px;
            font-size: 16px;
            display: inline-block;
            border-radius: 4px;
            appearance: none;
          }
          &:nth-child(1) {
            &:after {
              content: '▼';
              position: relative;
              right: 19px;
              font-size: 12px;
              color: $hpad-gray;
              pointer-events: none;
            }
          }
          &:nth-child(2):before {
            content: '/';
            position: relative;
            right: 6px;
            font-size: 12px;
            color: $hpad-black;
          }
          &:nth-child(2):after {
            content: '▼';
            position: relative;
            right: 20px;
            font-size: 12px;
            color: $hpad-gray;
            pointer-events: none;
          }
          &:nth-child(3):before {
            content: '/';
            position: relative;
            right: 6px;
            font-size: 12px;
            color: $hpad-black;
          }
          &:nth-child(3):after {
            content: '▼';
            position: relative;
            right: 20px;
            font-size: 12px;
            color: $hpad-gray;
            pointer-events: none;
          }
          &:nth-child(4):after {
            content: '▼';
            position: relative;
            right: 20px;
            font-size: 12px;
            color: $hpad-gray;
            pointer-events: none;
          }
          &:nth-child(5):before {
            content: ':';
            position: relative;
            right: 6px;
            font-size: 12px;
            color: $hpad-black;
          }
          &:nth-child(5):after {
            content: '▼';
            position: relative;
            right: 20px;
            font-size: 12px;
            color: $hpad-gray;
            pointer-events: none;
          }
          //付属しているボタンを消す
          a {
            display: none;
            &::after {
              display: none;
            }
          }
        }
      }
    }

    // 排泄介助の対応日時項目
    .form-confirmed {
      margin: 1rem 0 0 0;

      & > label {
        display: inline-block;
        width: 7rem;
        min-width: 7rem;
        padding: 0 1rem 0 1rem;
        margin: 0;
      }

      ul {
        display: inline-block;
        list-style: none;
        li {
          display: inline-block;
          // alt-datetimeは年から秒まで全て含んでいるため、時間、分以外は非表示にする
          #root_confirmed_year,
          #root_dosage_date_year {
            width: 65px;
            padding-left: 5px;
          }
          #root_confirmed_month,
          #root_dosage_date_month,
          #root_confirmed_day,
          #root_dosage_date_day,
          #root_confirmed_hour,
          #root_dosage_date_hour,
          #root_confirmed_minute,
          #root_dosage_date_minute {
            width: 50px;
            padding-left: 5px;
          }
          #root_confirmed_second,
          #root_dosage_date_second {
            display: none;
          }
          select {
            line-height: 32px;
            font-size: 16px;
            display: inline-block;
            border-radius: 4px;
            appearance: none;
          }
          &:nth-child(1) {
            &:after {
              content: '▼';
              position: relative;
              right: 19px;
              font-size: 12px;
              color: $hpad-gray;
              pointer-events: none;
            }
          }
          &:nth-child(2):before {
            content: '/';
            position: relative;
            right: 6px;
            font-size: 12px;
            color: $hpad-black;
          }
          &:nth-child(2):after {
            content: '▼';
            position: relative;
            right: 20px;
            font-size: 12px;
            color: $hpad-gray;
            pointer-events: none;
          }
          &:nth-child(3):before {
            content: '/';
            position: relative;
            right: 6px;
            font-size: 12px;
            color: $hpad-black;
          }
          &:nth-child(3):after {
            content: '▼';
            position: relative;
            right: 20px;
            font-size: 12px;
            color: $hpad-gray;
            pointer-events: none;
          }
          &:nth-child(4):after {
            content: '▼';
            position: relative;
            right: 20px;
            font-size: 12px;
            color: $hpad-gray;
            pointer-events: none;
          }
          &:nth-child(5):before {
            content: ':';
            position: relative;
            right: 6px;
            font-size: 12px;
            color: $hpad-black;
          }
          &:nth-child(5):after {
            content: '▼';
            position: relative;
            right: 20px;
            font-size: 12px;
            color: $hpad-gray;
            pointer-events: none;
          }
          //付属しているボタンを消す
          a {
            display: none;
            &::after {
              display: none;
            }
          }
        }
      }
    }

    // 排泄種類項目
    .form-result {
      & > label {
        display: block;
        width: 7rem;
        min-width: 7rem;
        padding: 0 1rem 0 1rem;
        margin: 0;
      }
    }

    // 尿の量項目
    .form-urine_amount {
      & > label {
        display: block;
        width: 7rem;
        min-width: 7rem;
        padding: 0 1rem 0 1rem;
        margin: 0;

        &:before {
          content: '●';
          width: 1rem;
          height: 1rem;
          margin-right: 5px;
          color: #5876ff;
        }
      }

      .field-radio-group {
        .radio-inline {
          min-width: 116px;
        }
      }
    }

    // 便の量項目
    .form-amount {
      // 項目のラベル
      & > label {
        display: block;
        width: 7rem;
        min-width: 7rem;
        padding: 0 1rem 0 1rem;
        margin: 0;

        &:before {
          content: '●';
          width: 1rem;
          height: 1rem;
          margin-right: 5px;
          color: #ffb500;
        }
      }

      .field-radio-group {
        .radio-inline {
          min-width: 116px;
        }
      }
    }

    // ブリストルスケール項目 (便の固さ)
    .form-firmness {
      padding: 1rem 0;
      border-bottom: 1.5px solid #efefef;

      .field-radio-group {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .radio-inline {
          margin-right: 5px;

          span {
            position: relative;

            input[type='radio'] {
              display: none;

              + span {
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                height: 100%;

                font-size: 14px !important;
                padding: 10px;
                min-width: 4rem;

                background-color: buttonColors('base');
                border: 1.5px solid #a4a4a4;
                border-radius: 4px;
              }

              & ~ .firmness-description {
                display: none;
              }

              &:hover ~ .firmness-description {
                position: absolute;
                display: block;
                top: 3rem;
                left: 0px;
                white-space: nowrap;
                height: 1.2rem;
                padding: 5px 1rem;
                z-index: 2;

                background-color: white;
                color: #565656;
                border: #565656 1.5px solid;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }

    // おむつ漏れ項目
    .form-leaked {
      position: absolute;
      display: flex;
      box-sizing: border-box;
      width: 50%;
      padding: 14px 1rem 14px 0;
      border-bottom: 1px solid #efefef;

      > label {
        display: flex;
        align-items: center;
        width: 7rem;
        min-width: 7rem;
        padding: 0 1rem 0 1rem;
        margin: 0;
      }

      .field-radio-group {
        display: flex;
        justify-content: space-between;
        width: 11rem;

        .radio-inline {
          width: 84px;
          height: 64px;
          margin-right: 5px;

          span {
            input[type='radio'] {
              display: none;

              + span {
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                height: 100%;

                font-size: 14px !important;
                padding: 10px;
                min-width: 4rem;

                background-color: buttonColors('base');
                border: 1.5px solid #a4a4a4;
                border-radius: 4px;
              }
            }
          }
        }
      }
    }

    // 排泄場所項目
    .form-type {
      display: flex;
      justify-content: space-between;

      box-sizing: border-box;
      width: 50%;
      padding: 14px 0 14px 1rem;
      margin-left: 50%;

      border-left: 1px solid #efefef;
      border-bottom: 1px solid #efefef;

      > label {
        display: flex;
        align-items: center;
        width: 7rem;
        padding: 0 1rem 0 0;
        margin: 0;
      }

      .field-radio-group {
        display: flex;
        justify-content: space-between;
        width: 11rem;

        .radio-inline {
          width: 84px;
          height: 64px;
          margin-right: 5px;

          span {
            input[type='radio'] {
              display: none;

              + span {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                box-sizing: border-box;
                width: 100%;
                height: 100%;

                border-radius: 4px;
                border: 1.5px solid #a4a4a4;
                background-color: buttonColors('base');
              }

              &:checked + span {
                border: 1.5px solid #595959;
              }
            }
          }
        }
      }
    }

    // メモ項目
    .form-description {
      position: relative;
      width: 100%;
      height: 76px;
      padding: 20px 0 0 0;
      display: inline-block;
      text-align: center;

      textarea {
        resize: none;
        box-sizing: border-box;
        text-align: left;
        width: 100%;
        height: 76px;
        padding: 10px;

        border: none;
        background-color: #f2f2f2;
        border-radius: 4px;
      }
    }

    // 排泄記録のメモ項目の文字数
    .description-counter {
      margin: 5px 0 1rem 0;
    }

    // ボタン
    .btn-set {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: flex-end;

      button {
        width: 6rem;
        height: 36px;
        border-radius: 4px;
        font-size: 16px;
      }

      button[type='submit'] {
        background-color: #ff7a01;
        color: #ffffff;
      }

      button[type='submit']:disabled {
        background-color: #bfbfbf;
      }

      .cancel-btn {
        color: #606266;
        background: transparent;
        margin-left: 10px;
        border: 1px solid #c0c4cc;
      }

      .delete-btn {
        background-color: #ff7777;
        color: #ffffff;
        margin-right: auto;
      }
    }
  }

  /**
   * 下剤記録タブのボディ
   * TODO: [WIP] 排泄記録タブのスタイルと分離
   */
  .laxative-note-container {
    .form-group.field {
      & > label {
        box-sizing: border-box;
        font-size: 1rem;
        font-weight: normal;
      }

      .radio-inline span span {
        font-size: 15px;
        font-weight: normal;
      }
    }

    // 時間ごとの記録の切り替え用のタブ
    .form-laxationLogsDataSelectTab {
      width: 100%;
      pointer-events: auto;
      border-bottom: 1px solid #dedede;

      .field-radio-group {
        width: 80%;
        display: flex;
        padding-bottom: 4px;

        .radio-inline {
          width: 20%;
          display: flex;
          justify-content: center;

          span {
            width: 100%;
            height: 100%;
            padding-bottom: 2px;
            display: flex;
            justify-content: center;

            span {
              font-size: 15px;
              font-weight: normal;
            }
          }
        }
      }

      input[type='radio'] {
        display: none;
      }

      input[type='radio']:checked + span {
        font-weight: bold;
        border-bottom: 2px solid #ff7a01;
      }
    }

    // 下剤投与の対応日時項目
    .form-dosage_date {
      margin: 1rem 0 0 0;

      & > label {
        display: inline-block;
        width: 7rem;
        min-width: 7rem;
        padding: 0 1rem 0 1rem;
        margin: 0;
      }

      ul {
        display: inline-block;
        list-style: none;

        li {
          display: inline-block;

          //alt-datetimeは年から秒まで全て含んでいるため、時間、分以外は非表示にする
          #root_confirmed_year,
          #root_dosage_date_year {
            width: 65px;
            padding-left: 5px;
          }
          #root_confirmed_month,
          #root_dosage_date_month,
          #root_confirmed_day,
          #root_dosage_date_day,
          #root_confirmed_hour,
          #root_dosage_date_hour,
          #root_confirmed_minute,
          #root_dosage_date_minute {
            width: 50px;
            padding-left: 5px;
          }
          #root_confirmed_second,
          #root_dosage_date_second {
            display: none;
          }
          select {
            line-height: 32px;
            font-size: 16px;
            display: inline-block;
            border-radius: 4px;
            appearance: none;
          }
          &:nth-child(1) {
            &:after {
              content: '▼';
              position: relative;
              right: 19px;
              font-size: 12px;
              color: $hpad-gray;
              pointer-events: none;
            }
          }
          &:nth-child(2):before {
            content: '/';
            position: relative;
            right: 6px;
            font-size: 12px;
            color: $hpad-black;
          }
          &:nth-child(2):after {
            content: '▼';
            position: relative;
            right: 20px;
            font-size: 12px;
            color: $hpad-gray;
            pointer-events: none;
          }
          &:nth-child(3):before {
            content: '/';
            position: relative;
            right: 6px;
            font-size: 12px;
            color: $hpad-black;
          }
          &:nth-child(3):after {
            content: '▼';
            position: relative;
            right: 20px;
            font-size: 12px;
            color: $hpad-gray;
            pointer-events: none;
          }
          &:nth-child(4):after {
            content: '▼';
            position: relative;
            right: 20px;
            font-size: 12px;
            color: $hpad-gray;
            pointer-events: none;
          }
          &:nth-child(5):before {
            content: ':';
            position: relative;
            right: 6px;
            font-size: 12px;
            color: $hpad-black;
          }
          &:nth-child(5):after {
            content: '▼';
            position: relative;
            right: 20px;
            font-size: 12px;
            color: $hpad-gray;
            pointer-events: none;
          }
          //付属しているボタンを消す
          a {
            display: none;
            &::after {
              display: none;
            }
          }
        }
      }
    }

    // 下剤名項目
    .form-laxativeName {
      margin-top: 20px;
      margin-bottom: 20px;

      label {
        display: inline-block;
        vertical-align: top;
        box-sizing: border-box;
        width: 7rem;
        min-width: 7rem;
        padding: 0 1rem 0 1rem;
        margin: 0;
        font-size: 1rem;
        font-weight: normal;
      }

      // 下剤名の選択ボックス
      #root_laxative_id {
        width: 250px;
        border: 1px solid #dedede;
        font-size: 1rem;
      }
    }

    // 下剤量項目
    .form-medicine-amount {
      & > label {
        display: inline-block;
        width: 7rem;
        min-width: 7rem;
        padding: 0 1rem 0 1rem;
        margin: 0;
      }

      float: left;
      margin: 0 5px 0 0;

      input {
        text-align: right;
        width: 5rem;
        padding: 5px;

        font-size: 1rem;
        border: 1px solid #dedede;
      }
    }

    .form-unit_id {
      margin-bottom: 2rem;

      #root_unit_id {
        padding: 5px;
        font-size: 1rem;
        border: 1px solid #dedede;
      }
    }

    // ボタン
    .btn-set {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: flex-end;

      button {
        width: 6rem;
        height: 36px;
        border-radius: 4px;
        font-size: 16px;
      }

      button[type='submit'] {
        background-color: #ff7a01;
        color: #ffffff;
      }

      button[type='submit']:disabled {
        background-color: #bfbfbf;
      }

      .cancel-btn {
        color: #606266;
        background: transparent;
        margin-left: 10px;
        border: 1px solid #c0c4cc;
      }

      .delete-btn {
        background-color: #ff7777;
        color: #ffffff;
        margin-right: auto;
      }
    }
  }

  .rjsf {
    fieldset {
      .form-group {
        &::after {
          clear: both;
        }

        div {
          display: inline-block;
        }

        .react-autosuggest__input {
          font-size: 12px;
          height: 20px;
        }

        .react-autosuggest__suggestions-container {
          font-size: 24px;
          display: block;
        }

        .react-autosuggest__suggestion {
          line-height: 20px;
        }
      }

      .field-radio-group {
        label {
          font-size: 16px;
          display: inline;
        }
      }

      .deleteButtons {
        width: 100%;
        display: flex;
      }

      .form-alert {
        padding-top: 20px;
        > label {
          display: inline-block;
          font-size: 16px;
          font-weight: bold;
          padding-right: 5px;
        }

        input[type='radio'] {
          display: none;
        }

        div {
          button[type='submit'] {
            display: none;
          }
        }
      }

      @function buttonColors($key) {
        @return map-get($button-colors, $key);
      }

      $button-colors: (
        'base': #f2f2f2,
        'disabled': #606266,
        'none': #b4b4b4,
        'feces': #ffb500,
        'urine': #5876ff,
        'watery': #73c19d,
        'exist': #ff6363,
        'type': #44d1d1
      );

      $button-colors: map-merge(
        $button-colors,
        (
          'urine_and_feces':
            linear-gradient(
              90deg,
              buttonColors('urine') 0%,
              buttonColors('urine') 50%,
              buttonColors('feces') 50%,
              buttonColors('feces') 100%
            )
        )
      );

      @function buttonIcons($name) {
        @return url('../../../../images/#{$name}.svg');
      }

      /**
       * ボタンのスタイルとアイコンを、radiobuttonのvalue(feces,urine,exists..)を指定して設定できる。
       * valueを指定しなければ各ボタンに適用される。
       */
      @mixin butonBehavior(
        $bgColor,
        $inputValue: null,
        $icon: null,
        $baseFontColor: black,
        $checkedFontColor: white
      ) {
        @if ($inputValue!=null) {
          input[type='radio'][value='#{$inputValue}'] {
            + span {
              @include buttonSurface('base', $icon);
              color: $baseFontColor;
            }
            &:checked + span {
              @include buttonSurface($bgColor, $icon);
              color: $checkedFontColor;
            }
          }
        } @else {
          input[type='radio'] {
            + span {
              @include buttonSurface('base', $icon);
              color: $baseFontColor;
            }
            &:checked + span {
              @include buttonSurface($bgColor, $icon);
              color: $checkedFontColor;
            }
          }
        }

        .field-radio-group {
          .disabled > span > span {
            background-color: buttonColors('disabled');
            color: #a6a6a6;
          }
        }
      }

      @mixin buttonSurface($inputValue, $icon: null) {
        @if ($icon!=null) {
          background: buttonIcons($icon);
          background-repeat: no-repeat;
          background-size: 45%;
          background-position: center center;
        }
        background-color: buttonColors($inputValue);
      }

      @mixin dialog-button() {
        padding: 14px 0px;
        border-bottom: 1px solid #efefef;
        display: flex;

        .field-radio-group {
          display: flex;
          justify-content: space-between;
          width: 85%;
        }

        > label {
          display: flex;
          align-items: center;
        }

        div {
          label {
            font-size: 16px;
            display: flex;
            font-weight: bold;
            height: 36px;

            > span {
              width: 100%;
              height: 100%;
              display: flex;
            }
          }
        }

        input[type='radio'] {
          display: none;
          + span {
            border: 1.5px solid #a4a4a4;
            background-color: buttonColors('base');
            border-radius: 4px;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &:checked + span {
            border: 1.5px solid #595959;
          }
        }
      }

      // 排泄種別の選択項目
      .form-result {
        @include dialog-button();

        div {
          label {
            width: 6rem;
            height: 5.25rem;
          }
        }

        input[type='radio'] + span {
          align-items: flex-end;
        }

        @include butonBehavior('none', 'none');

        input[type='radio'][value='none'] + span {
          align-items: center;
        }

        @each $result in 'urine', 'feces' {
          @include butonBehavior($result, $result, $result);
        }

        input[type='radio'][value='urine_and_feces'] {
          + span {
            background: buttonIcons('urine'), buttonIcons('feces');
            background-repeat: no-repeat;
            background-size: 40%, 40%;
            background-position: 10% 20%, 90% 60%, 90%;
            background-color: buttonColors('base');
          }
          &:checked + span {
            background: buttonIcons('urine'), buttonIcons('feces'),
              buttonColors('urine_and_feces');
            background-repeat: no-repeat;
            background-size: 40%, 40%, 100%;
            background-position: 10% 20%, 90% 60%, 90%;
            color: #ffffff;
          }
        }
      }

      .form-urine_amount {
        @include dialog-button();
        @include butonBehavior('urine');
      }

      .form-amount {
        @include dialog-button();
        @include butonBehavior('feces');
      }

      .form-firmness {
        @include butonBehavior('feces');
        @include butonBehavior('watery', 'watery');
      }

      .form-leaked {
        @each $leaked in 'none', 'exist' {
          @include butonBehavior($leaked, $leaked);
        }
      }

      .form-type {
        div > label {
          height: 84px;
          width: 84px;
        }

        input[type='radio'] + span > span {
          align-items: flex-end;
        }

        @each $type in 'diaper', 'toilet' {
          @include butonBehavior('type', $type, $type);
        }
      }

      //---ここまでボタン---
      .form-pulldown {
        position: relative;
        select {
          font-size: 16px;
          display: inline-block;
          appearance: none;
          width: 160px;
          height: 32px;
          padding: 0 5px;
          border-radius: 4px;
          border: 1px solid $hpad-border-gray;
          // color: $hpad-black;
        }
        select:disabled {
          background-color: #606266;
        }
        &::after {
          content: '▼';
          position: relative;
          right: 20px;
          font-size: 12px;
          color: $hpad-gray;
          pointer-events: none;
        }
        input {
          height: 20px;
          font-size: 12px;
          min-width: 300px;
        }
      }
      .form-hidden {
        display: none;
      }
    }
    .error {
      color: $hpad-red;
    }

    .firmness-tips {
      > label {
        &:before {
          content: '●';
          width: 16px;
          height: 16px;
          color: #5876ff;
        }
      }
    }
  }

  p {
    .btn {
      display: none !important;
    }
  }
}
