@import "@/styles/scss/_base.scss";
@import "@/styles/scss/_util.scss";
@import "@/styles/breakpoints.scss";

.info-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;
  margin-top: 1rem;

  @media screen and (max-width: $md) {
    display: none;
  }

  & .icon-icons_arrow {
    color: #fff;
    width: 16.25px;
    height: 9.38px
  }

  .header-menu {
    display: block;
    position: absolute;
    width: 200px;
    top: 50px;
    right: 0;
    z-index: 1000;

    font-size: 14px;
    border-radius: 5px;
    background-color: $hpad-white;
    box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;

    .name {
      box-sizing: border-box;
      padding: 1rem;

      color: #565656;
      font-size: 14px;
    }

    .menu-items {
      box-sizing: border-box;
      box-shadow: rgba(55, 53, 47, 0.15) 0px -1px 0px;
      padding: 10px 0 10px 0;

      .menu-item {
        box-sizing: border-box;
        margin: 0 0 5px 0;
        padding: 8px 1rem 8px 1rem;
        color: #565656;

        &:hover {
          cursor: pointer;
          transition: 0.5s;
          background-color: #ddd;
        }
      }
    }

    .version {
      box-sizing: border-box;
      text-align: right;
      padding: 8px 1rem 0.8rem 1rem;

      box-shadow: rgba(55, 53, 47, 0.15) 0px -1px 0px;
      font-size: 12px;
      color: #565656;
    }
  }
}