@import "@/styles/scss/_base.scss";
@import "@/styles/scss/_util.scss";

.calendar-date-picker {
  display: inline-block;
  text-align: center;

  .today-select-button {
    margin: 0 0 0.5rem 0;
    padding: 4px 8px;

    border-radius: 5px;
    background-color: $hpad-orange;
    color: $hpad-white;
    font-size: 14px;

    & > div {
      vertical-align: middle;
      display: inline-block;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .rjsf {
    .form-group fieldset .form-group .datetime-picker {
      .without-time {
        width: initial;
        z-index: 10;
        padding: 0.5rem;
      }
    }

    th {
      padding: 3px;
    }

    .datetime-picker {
      .toolbar {
        display: flex;
        justify-content: space-between;

        width: initial;
        margin: 0 0 0.5rem 0;

        .current-month {
          width: initial;
          font-weight: bold;
        }
      }
    }

    button[type='submit'] {
      display: none;
    }
  }
}