
.summary {
  display: inline-block;

  margin: 1rem 0 0 0;
  padding: 1rem 10px;

  font-size: 1rem;
  line-height: 1.2rem;

  border-radius: 5px;
  border: solid 2px #f2992b;
}
