
// NOTE: expiration だけ App.scss から抜き出してきた

// コンポーネント名と合わせつつ、id にする
// #notification-table {
.user-table {
  // 利用者名要素
  .name_card {
    span {
      .high_priority_alert {
        // 利用者名の横のアイコンの経過時間ラベル
        .expiration {
          width: 64px;
          white-space: normal;

          font-family: "Noto Sans";
          font-size: 13px;
        }
      }
    }
  }

  // 通知時刻要素
  .alert_date {
    span {
      .high_priority_alert {
        position: absolute;
        display: inline-block;
        top: -60px;
        left: 50px;
        font-size: 6rem;

        // 通知時刻の横のアイコンの経過時間ラベル
        .expiration {
          position: absolute;
          white-space: normal;
          top: 75px;
          left: 4px;

          font-family: "Noto Sans";
          font-size: 13px;
        }
      }
    }
  }
}