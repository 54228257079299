@import "@/styles/scss/_base.scss";
@import "@/styles/scss/_util.scss";

.group-box-head {
  input[type='checkbox'] {
    display: none;
  }

  .checkboxes {
    .checkbox {
      display: flex;

      .icon-icons_edit_2 {
        color: $hpad-gray;
        position: absolute;
        top: 16px;
        right: 0px;
        font-size: 28px;
      }

      & > label span {
        span {
          transition: background-color 0.2s linear;
          position: relative;
          padding: 0 0 0 42px;
          cursor: pointer;
          display: block;
          margin-bottom: 15px;

          &::after {
            transition: border-color 0.2s linear;
            background-color: #fff;
            content: '';
            position: absolute;
            left: 15px;
            top: 50%;
            display: block;
            margin-top: -10px;
            width: 16px;
            height: 16px;
            border: 2px solid #ccc;
            z-index: 1;
          }

          &::before {
            transition: opacity 0.2s linear;
            position: absolute;
            left: 21px;
            top: 50%;
            display: block;
            margin-top: -7px;
            width: 5px;
            height: 9px;
            border-right: 3px solid $hpad-green;
            border-bottom: 3px solid $hpad-green;
            content: '';
            opacity: 0;
            transform: rotate(45deg);
            z-index: 2;
          }
        }

        input[type='checkbox']:checked + span::before {
          opacity: 1;
        }
      }

      // 編集ボタンを押した後のグループ名入力フォーム
      .edit-group-name-form {
        position: relative;
        top: -10px;

        .rjsf {
          display: flex;
          align-items: center;
        }

        .form-group {
          & > div {
            display: flex;
            align-items: center;
          }
        }

        .group-name {
          display: inline;
        }

        .cancel-button {
          margin: 0 0 0 0.5rem;
          background-color: $hpad-gray;
          color: $hpad-white;
        }
      }
    }
  }
}