// カラー定義
$hpad-white: #fff;
$hpad-gray: #90a4ae;
$hpad-light-gray: #e9edef;
$hpad-green: #26a69a;
$hpad-light-orange: #ffecac;
$hpad-orange: #f39a2b;
$hpad-border-gray: #ddd;
$hpad-black: #000;
$hpad-red: #cc0000;
$hpad-blue: #00b7ee;
$hpad-brown: #6a3906;

// 全体クラス
@font-face {
  font-family: 'Yu Gothic';
  src: local('Yu Gothic Medium');
  font-weight: 100;
}
@font-face {
  font-family: 'Yu Gothic';
  src: local('Yu Gothic Medium');
  font-weight: 200;
}
@font-face {
  font-family: 'Yu Gothic';
  src: local('Yu Gothic Medium');
  font-weight: 300;
}
@font-face {
  font-family: 'Yu Gothic';
  src: local('Yu Gothic Medium');
  font-weight: 400;
}
@font-face {
  font-family: 'Yu Gothic';
  src: local('Yu Gothic Bold');
  font-weight: bold;
}

body {
  font-family: 'Yu Gothic', YuGothic, sans-serif;
}

body {
  font-size: 14px;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
}
input {
  padding: 10px;
  border: 1px solid $hpad-border-gray;
}
input[type='submit'] {
  appearance: none;
  border: 0;
}
input:focus,
select:focus {
  outline: 0;
}
button {
  border: none;
}
a {
  text-decoration: none;
}

a:active,
a:visited {
  color: $hpad-gray;
}

/*
 * 共通パーツ
 */

// ヘッダー
.app_header {
  min-width: 1183px;
  height: 56px;
  background: linear-gradient(to right, #f2992b, #e27130);
  justify-content: space-between;
  position: relative;

  span {
    color: #fff;
  }

  .header-left {
    display: flex;
    p {
      display: flex;
      align-items: start;
    }
    span {
      padding-left: 8px;
    }
    .logo {
      height: 32.8px;
      margin-top: 14px;
      margin-bottom: 9.2px;
      margin-left: 26px;
    }
    .view-time {
      white-space: nowrap;
      margin-top: 25px;
      margin-left: 50px;
    }
  }

  .header-center {
    height: 56px;
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    .menu-text {
      color: rgba(255, 255, 255, 0.5);
      font-size: 16px;
    }
    .menu-button {
      & > div {
        width: 144px;
        height: 56px;
        padding-top: 23.8px;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        &:hover {
          background-color: #4d3830;
        }
      }
      &:last-child > div {
        border: none;
      }
      &.active {
        border-bottom: 3.4px solid #fff;
        .menu-text {
          color: #fff;
        }
      }
    }
  }
  .header-right {
    margin-right: 33px;
    display: flex;
    span {
      padding: 25px 11px 0px 15px;
      white-space: nowrap;
    }
    .setting-button {
      padding: 22px 18px 0px;
      display: flex;
      align-items: start;
      box-sizing: border-box;

      &:last-child > div {
        border: none;
      }

      &.active {
        border-bottom: 3.4px solid #fff;
      }
    }
    .info-button {
      width: 30px;
      height: 30px;
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .icon-icons_setting2 {
    color: #fff;
    font-size: 20px;
  }
  .icon-icons_arrow {
    color: #fff;
    width: 16.25px;
    height: 9.38px;
  }
}

// グローバルメニュー
.app_menu {
  border: 1px solid $hpad-border-gray;
  width: 80px;
  min-width: 80px;
  text-align: center;
  font-size: 12px;
  color: $hpad-gray;
  height: 100%;

  .menu-button {
    & > div {
      border-bottom: 1px solid $hpad-border-gray;
      padding: 15px 0;
      text-decoration: none;
      &:hover {
        @extend %hover-animation;
        background-color: $hpad-gray;
        color: $hpad-white;
      }
      &.active {
        background-color: $hpad-gray;
        color: $hpad-white;
        width: 80px;
      }
    }
    &:last-child {
      & > div {
        border: none;
      }
    }
  }

  .icon {
    font-size: 38px;
  }
}

// ローディング画面
.app_loading {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(256, 256, 256, 0.8);
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;

  &.hide {
    display: none;
  }

  & > div {
    margin: auto;
    text-align: center;
    p {
      font-size: 28px;
      line-height: 1.4;
      margin-bottom: 20px;
      span {
        font-size: 21px;
        display: block;
      }
    }
  }
}

// ダイアログ画面
.app_dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(256, 256, 256, 0.8);
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;

  &.hide {
    display: none;
  }

  & > .content {
    margin: auto;
    text-align: center;
    width: 80%;
    p {
      font-size: 28px;
      line-height: 1.4;
      margin-bottom: 20px;
      span {
        font-size: 21px;
        display: block;
      }
    }
  }
  .choiceButtons {
    justify-content: center;
    div {
      margin: 0 10px;
      color: $hpad-white;
      background-color: $hpad-gray;
      padding: 20px 50px;
      border-radius: 15px;
      cursor: pointer;
    }
  }
  .closeButton {
    position: absolute;
    display: inline-block;
    top: 0px;
    right: 0px;
    width: 64px;
    height: 64px;
    border-radius: 64px;
    border: solid 4px $hpad-gray;
    background-color: white;
    margin: 0;
    padding: 0;
    transform: scale(0.5);
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      display: inline-block;
      top: 13%;
      left: 45%;
      width: 6px;
      height: 70%;
      border: 0;
      margin: 0;
      padding: 0;
      background-color: $hpad-gray;
      -moz-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &:after {
      content: '';
      position: absolute;
      display: inline-block;
      top: 13%;
      left: 45%;
      width: 6px;
      height: 70%;
      border: 0;
      margin: 0;
      padding: 0;
      background-color: $hpad-gray;
      -moz-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    &:hover {
      background-color: $hpad-gray;
      &:before {
        background-color: white;
      }
      &:after {
        background-color: white;
      }
    }
  }
}

// コンテンツの横幅
.app_container {
  width: 1090px;
  margin-left: 110px;
}

.home_container {
  width: 100%;
  min-width: 1183px;
  margin: 0 110px;
}
@media screen and (max-width: 1439px) {
  .home_container {
    margin: 0;
  }
}

// プルダウン
.hpad-pulldown-1 {
  color: $hpad-black;
  display: inline-block;
  vertical-align: middle;
  position: relative;

  & > p {
    display: inline-block;
    margin-right: 10px;
  }
  span {
    padding-right: 10px;
  }
  select {
    display: inline-block;
    appearance: none;
    min-width: 200px;
    padding: 8px;
    border-radius: 0px;
    background-color: $hpad-white;
    border: 1px solid $hpad-border-gray;
    color: $hpad-black;
  }
  &::after {
    content: '▼';
    position: absolute;
    top: 12px;
    right: 10px;
    font-size: 10px;
    color: $hpad-gray;
    pointer-events: none;
  }
}

// 継承専用テーブルスタイル
%hpad-table-1 {
  th,
  td {
    border: 1px solid $hpad-border-gray;
  }
  th {
    background-color: $hpad-gray;
    color: $hpad-white;
    padding: 22px 10px;
  }
  td {
    vertical-align: middle;
    padding: 21px;
  }
}

//タブ
.hpad-tab-1 {
  .tab {
    color: $hpad-gray;
    background-color: $hpad-white;
    font-size: 15px;
    padding: 12px 28px;
    min-width: 120px;
    border: solid $hpad-border-gray;
    border-width: 1px 1px 0px;
    justify-content: center;
    margin-right: 10px;
    .icon {
      font-size: 40px;
      margin-right: 5px;
    }
    &:hover {
      @extend %hover-animation;
      background-color: $hpad-gray;
      color: $hpad-white;
    }
    &.active {
      background-color: $hpad-gray;
      color: $hpad-white;
    }
  }
  .tab-content {
    border: 1px solid $hpad-border-gray;
  }
}

.tab-content {
  padding: 50px;
}

//フォーム
.hpad-form-1 {
  input {
    font-size: 14px;
  }
  input[disabled] {
    background-color: #eee;
    color: #666;
  }
  button[type='submit'] {
    padding: 10px 20px;
    background-color: $hpad-orange;
    color: $hpad-white;
    border-radius: 10px;
  }
  button[type='button'] {
    padding: 10px 20px;
    background-color: $hpad-orange;
    color: $hpad-white;
    border-radius: 10px;
  }
  // react-jsonschema-formに依存
  .rjsf {
    .field {
      display: flex;
      align-items: center;
      .control-label {
        min-width: 138px;
        font-size: 13px;
      }
    }
    fieldset {
      & > div {
        margin-top: 15px;
      }
      & > div:first-child {
        margin-top: 0px;
      }
    }
  }
  // プルダウンはuiSchemaで個別にスタイルつける
  .form-pulldown {
    position: relative;
    select {
      display: inline-block;
      appearance: none;
      min-width: 200px;
      padding: 8px;
      border-radius: 0px;
      background-color: $hpad-white;
      border: 1px solid $hpad-border-gray;
      color: $hpad-black;
    }
    &::after {
      content: '▼';
      position: absolute;
      top: 12px;
      right: 10px;
      font-size: 10px;
      color: $hpad-gray;
      pointer-events: none;
    }
  }
}

// react-autosuggestのクラス
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 38px;
  border: 1px solid $hpad-border-gray;
  background-color: #fff;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 100px;
  overflow-y: auto;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

// ツールチップ
.tooltip {
  position: absolute;
  background-color: #ffffff;
  border: solid 1px #000000;
  padding: 0.2rem;
  z-index: 10;
}

.tooltip-parent {
  position: relative;
  .tooltip {
    font-size: 1.2rem;
    transition: opacity 0.2s, visibility 0.2s;
    opacity: 0;
    visibility: hidden;
    white-space: nowrap;
    position: absolute;
    padding: 0.1rem 0.3rem;
    bottom: -1.8rem;
    border-radius: 0.2rem;
    background-color: #ffffff;
    border: solid 1px #000000;
    z-index: 999;
  }
}
.tooltip-parent:hover {
  .tooltip {
    opacity: 1;
    visibility: visible;
  }
}
