.clearfix:after {
    content:" ";
    display:block;
    clear:both;
}

.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: start;
}
.flex-wrap {
  flex-wrap: wrap;
}
.space-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.flex-start {
  justify-content: flex-start;
}
.text-center {
  text-align: center;
}
.mr20 {
  margin-right: 20px;
}
.mr30 {
  margin-right: 30px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.font-hpad-gray {
  color: $hpad-gray;
}
.font-hpad-red {
  color: $hpad-red;
}

// 共通ホバーアニメーション
%hover-animation {
  transition: all 0.25s cubic-bezier(0.22, 0.61, 0.36, 1);
}
