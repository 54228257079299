.tooltip-container {
  position: relative;

  .tooltip {
    position: absolute;

    // デフォルト値
    top: 0;
    left: 0;

    line-height: 1.3rem;
    padding: 5px 10px;
    z-index: 999;

    background-color: #ffffff;
    border: solid 1px #565656;
    border-radius: 5px;

    font-size: 14px;
    font-family: "Noto Sans";
    color: #565656;
  }
}
